import { FunctionComponent } from 'react';
import { DataSource, DataSourceIDEnum, DataSourceType, EDataSourceCategory } from '../../models/dataSource';
import { IModalContentProps } from '../../models/modals';
import { EmptyDataSourceContainer, FileDataSourceContainer } from './components';
import { CodeDataSourceContainer } from './components/categories/code';
import { CrmDataSourceContainer } from './components/categories/crm';
import { AmazonS3DataSourceContainer } from './components/categories/other';

interface IDataSourceEditProps {
  dataSourceType: DataSourceType;
  onSuccess?: (createdDataSource: Partial<DataSource>) => void;
}

const DataSourceEdit: FunctionComponent<IDataSourceEditProps & IModalContentProps> = ({ closeModal, onSuccess, dataSourceType }) => {
  const renderModalContent = (dataSourceType: DataSourceType) => {
    const props = {
      dataSourceType,
      closeModal,
      onSuccess,
    };

    if (dataSourceType.id === DataSourceIDEnum.AmazonS3) {
      return <AmazonS3DataSourceContainer {...props} />;
    }

    switch (dataSourceType.category) {
      case EDataSourceCategory.CODE:
        return <CodeDataSourceContainer {...props} />;
      case EDataSourceCategory.CRM:
        return <CrmDataSourceContainer {...props} />;
      case EDataSourceCategory.FILE:
        return <FileDataSourceContainer {...props} />;
      default:
        return <EmptyDataSourceContainer {...props} />;
    }
  };

  return renderModalContent(dataSourceType);
};

export default DataSourceEdit;
