import i18n from '../../../../../../../../../localization';

export const QUICK_START_AUDIENCES_TABLE_COLUMNS = [
  {
    label: '',
    className: 'text-left',
  },
  {
    label: i18n.t('audiences:table.audience_name'),
    className: 'text-left',
  },
  {
    label: i18n.t('audiences:table.sync'),
    className: 'text-left',
  },
  {
    label: i18n.t('audiences:table.change'),
    className: 'text-left',
  },
  {
    label: i18n.t('audiences:table.total_users'),
    className: 'text-right',
  },
];
