import { CheckIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AmazonS3ConfigurationCompleted: FC = () => {
  const { t } = useTranslation('data_source_edit');

  return (
    <div className='flex flex-col justify-center text-center'>
      <span className='m-auto w-10 h-10 flex items-center justify-center bg-green-600 rounded-full'>
        <CheckIcon className='w-6 h-6 text-white-100' aria-hidden='true' />
      </span>
      <h3 className='font-medium text-lg text-gray-900 mt-3 mb-3'>{t('file.confirmation.configuration_completed')}</h3>
      <p className='font-normal text-base text-gray-900 w-2/3 m-auto'>{t('file.confirmation.configuration_and_upload_completed_description')}</p>
    </div>
  );
};
