import { UserGroupIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeAgo } from '../../../../components/time-ago';
import { AudienceSyncTimeBasedSchedule, BaseAudience } from '../../../../models/audiences';
import AudienceStats from '../AudienceStats';

type AudienceColumnProps = {
  audience: BaseAudience;
};

export const AudienceIconColumn = () => (
  <td className='pl-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
    <div className={'inline-block align-middle mr-3 text-gray-500'}>
      <UserGroupIcon className='size-5' />
    </div>
  </td>
);

export const AudienceIdColumn: FC<AudienceColumnProps> = ({ audience }) => (
  <td className='pl-3 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
    <p className='py-2 text-sm font-medium text-gray-900'>{audience.id}</p>
  </td>
);

export const AudienceNameColumn: FC<AudienceColumnProps> = ({ audience }) => {
  const { t } = useTranslation('audiences');

  return (
    <td className='pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
      <div className='py-2 text-sm font-medium text-blue-500 cursor-pointer'>
        {audience.name}
        {audience.extended && (
          <span className='items-center mx-2 px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800'>{t('extended')}</span>
        )}
      </div>
    </td>
  );
};

export const AudienceSyncColumn: FC<AudienceColumnProps> = ({ audience }) => {
  const { t } = useTranslation('audiences');

  const renderScheduledSync = () => {
    const syncConfig = audience.scheduleConfiguration as AudienceSyncTimeBasedSchedule;
    const cardinality: string = syncConfig.repeatsEvery === 1 ? 'one' : 'other';

    return t(`table.syncs.scheduled_${syncConfig.unit}_${cardinality}` as any, { count: syncConfig.repeatsEvery });
  };

  const renderSync = () => {
    switch (audience.scheduleType) {
      case 'REALTIME':
        return t('table.syncs.realtime');

      case 'MANUAL':
        return t('table.syncs.manual');

      case 'SCHEDULED':
        return renderScheduledSync();

      default:
        return '-';
    }
  };

  return (
    <td className='pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
      <p className='py-2 text-sm font-medium text-gray-900'>{renderSync()}</p>
    </td>
  );
};

export const AudienceCreatedAtColumn: FC<AudienceColumnProps> = ({ audience }) => (
  <td className='pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
    <p className='py-2 text-sm font-medium text-gray-900'>{<TimeAgo time={audience.createdOn} />}</p>
  </td>
);

export const AudienceTotalUsersColumn: FC<AudienceColumnProps> = ({ audience }) => (
  <td className='pl-6 pr-4 py-1 whitespace-nowrap text-sm font-medium text-gray-900'>
    <div className='py-2 text-sm font-medium text-gray-900'>{<AudienceStats audience={audience} />}</div>
  </td>
);
