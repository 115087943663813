import * as csvParser from 'papaparse';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JSX } from 'react/jsx-runtime';
import { Button } from '../../../../components/buttons';
import { SpinnerIcon } from '../../../../components/icons';
import { useModalContext } from '../../../../hooks/useModalContext';
import { DataSourceAmazonS3Config, TDataSource } from '../../../../models/dataSource';
import { useLazyGetDataSourceFileLinesQuery, usePostDataSourceDownloadUrlMutation } from '../../../../services/dataSources';
import { useWorkspace } from '../../../workspaces/hooks';
import { PreviewCSVTable } from '../table';
import { ResyncS3DataSourceModal } from './settings/modals/ResyncS3DataSourceModal';

type AmazonS3DataSourceDataProps = {
  dataSource?: TDataSource<DataSourceAmazonS3Config>;
};

export const AmazonS3DataSourceData: FunctionComponent<AmazonS3DataSourceDataProps> = ({ dataSource }) => {
  const { t } = useTranslation('data_source_details');

  const workspace = useWorkspace();

  const [postDataSourceDownloadUrl, { data, isLoading, isSuccess }] = usePostDataSourceDownloadUrlMutation();
  const [getDataSourceFileLines, { data: lines, isLoading: isLoadingLines }] = useLazyGetDataSourceFileLinesQuery();
  const [parsedFileLines, setParsedFileLines] = useState<string[][]>([]);

  useEffect(() => {
    if (dataSource) {
      postDataSourceDownloadUrl({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
      });

      getDataSourceFileLines({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
      });
    }
  }, []);

  useEffect(() => {
    if (lines) {
      const results = csvParser.parse(lines.join('\n'), {
        skipEmptyLines: true,
      });
      setParsedFileLines((results?.data as string[][]) || []);
    }
  }, [lines]);

  const handleDownloadFileClicked = () => {
    if (data) {
      window.open(data.url, '_parent');
    }
  };

  let view: JSX.Element;
  if (isLoading || isLoadingLines || parsedFileLines.length === 0) {
    view = (
      <div className='py-8 flex justify-center items-center'>
        <span className='text-blue-500 opacity-75 my-0 relative inline-block w-7 h-7 pt-3'>
          <SpinnerIcon className='ml-2 mr-3 h-5 w-5' loading />
        </span>
      </div>
    );
  } else {
    view = (
      <div className='py-8'>
        {lines?.length && <h1 className='text-base font-bold text-gray-900 mb-2 leading-4'>{t('data.preview_description', { count: lines?.length })}</h1>}
        {parsedFileLines.length > 0 && (
          <PreviewCSVTable fileContent={parsedFileLines} isDefaultHeaderCorrect={!!dataSource?.configuration.isDefaultHeader} dataSource={dataSource} />
        )}
      </div>
    );
  }

  const { openModal } = useModalContext();

  const handleResyncClick = () => {
    openModal({
      renderContent: ResyncS3DataSourceModal,
      renderContentProps: {
        dataSource,
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false,
    });
  };

  const now = new Date();
  const url = dataSource.configuration.path;

  return (
    <div className='flex flex-col'>
      <div className='flex-1'>
        <div className='flex py-8'>
          <div className='flex-col mr-8'>
            <h1 className='text-base font-bold text-gray-900 mb-2 leading-4'>{t('data.title')}</h1>
            <p className='text-base text-gray-800'>{url?.substring(url.lastIndexOf('/') + 1)}</p>
          </div>
          <Button onClick={handleDownloadFileClicked} disabled={!isSuccess}>
            {t('data.download_file')}
          </Button>
        </div>
      </div>
      <div className='flex-1'>{view}</div>
      <div className='flex flex-col gap-[14px]'>
        <h2 className='text-base capitalize-first font-bold text-gray-900 leading-4'>{t('data.syncTheDataSource')}</h2>
        <h2 className='text-base text-gray-900 leading-4'>{t('data.syncTheDataSource_note')}</h2>
        <Button onClick={handleResyncClick} disabled={!isSuccess} className='w-fit capitalize h-12'>
          {t('data.manualSync')}
        </Button>
        <i className='text-base text-gray-900 leading-4 capitalize-first'>
          {t('data.lastSync', {
            date: `${now.toLocaleDateString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}`,
            time: `${now.toLocaleTimeString(undefined, {
              hour12: false,
            })}`,
          })}
        </i>
      </div>
    </div>
  );
};
