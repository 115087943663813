import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import DataSourcesIcon from '../../../../assets/icons/fileSource.svg?react';
import { Loadable } from '../../../../components/loaders';
import { ETABS } from '../../../../models/tabs';
import { useGetCampaignQuery } from '../../../../services/endpoints/campaigns';
import { MainContentWrapper } from '../../../layout/main';
import { Breadcrumb, TopbarTabs } from '../../../layout/topbar';
import RouteConstants from '../../../router/RouteConstants';
import { useWorkspace } from '../../../workspaces/hooks';
import { CampaignDetailsDataTab } from './CampaignDetailsDataTab';

export const CampaignDetails: FC = () => {
  const { t } = useTranslation('campaign');
  const history = useHistory();

  const { id: campaignId } = useParams<{ id: string }>();
  const workspace = useWorkspace();

  const { data, isFetching, isUninitialized, isError } = useGetCampaignQuery({
    campaignId,
    workspaceId: workspace.id,
  });

  const tabs = [{ id: ETABS.DATA, current: true, label: t('sourceAndRules'), icon: () => <DataSourcesIcon className='inline size-5 mr-1' /> }];

  const breadcrumbs = useMemo(
    () => [...[{ label: t('campaigns'), path: RouteConstants.campaigns }], ...(data ? ([{ label: data.name }] satisfies Breadcrumb[]) : [])],
    [data]
  );

  const renderTab = () => {
    const activeTabId = tabs.find((tab) => tab.current)?.id;

    switch (activeTabId) {
      case ETABS.DATA:
        return <CampaignDetailsDataTab />;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (isError) {
      history.push(RouteConstants.defaultRoute);
    }
  }, [isError]);

  return (
    <>
      <TopbarTabs tabs={tabs} onTabChange={() => {}} breadcrumbs={breadcrumbs} />
      <MainContentWrapper childClassName='flex flex-col px-12 pt-14 h-full'>
        <Loadable isLoading={isFetching || isUninitialized}>{renderTab()}</Loadable>
      </MainContentWrapper>
    </>
  );
};
