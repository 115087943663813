import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { ExternalLinkIcon, TrashIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/buttons";
import { Loading } from "../../../components/loaders";
import { useModalContext } from "../../../hooks/useModalContext";
import { DistributionChannelConnector } from "../../../models/distributionChannels";
import { IModalContentProps } from "../../../models/modals";
import { ToastType } from "../../../models/toast";
import { WorkspaceId } from "../../../models/workspace";
import { useAppDispatch } from "../../../reducers";
import { useAuthenticateConnectorMutation, useDeauthenticateConnectorMutation, useGetDistributionChannelConnectorsQuery, useLazyGetDistributionChannelConnectorsQuery } from "../../../services/endpoints/distributionChannels";
import { concatClassNames, popupCenter } from "../../../utils";
import RouteConstants from "../../router/RouteConstants";
import { showToast } from "../../toasts/toastsSlice";
import { useWorkspace } from "../../workspaces/hooks";

export default function ConnectorsCatalog() {
  const { t } = useTranslation("destinations");
  const { id: workspaceId } = useWorkspace();
  const { data, isLoading } = useGetDistributionChannelConnectorsQuery({ workspaceId });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <div className='flex w-full mb-8'>
        <div className='flex-1 m-auto'>
          <h1 className='text-2xl font-semibold text-gray-900 leading-7'>{t('catalog.connectors')}</h1>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 gap-12 md:grid-cols-3 md:gap-12 pt-4 mb-12">
          {data?.map((c) => (
            <ConnectorCard key={c.name} connector={c} />
          ))}
        </div>
      </div>
    </div>
  );
}

type ConnectorCardProps = {
  connector: DistributionChannelConnector;
  onClick?: () => void;
};

const ConnectorCard = ({ connector, onClick }: ConnectorCardProps) => {
  const { openModal } = useModalContext();
  const dispatch = useAppDispatch();
  const { id: workspaceId } = useWorkspace();
  const { name, icon, installed, authenticated, description, integrationPartner } = connector;
  const [authenticateConnector, { isLoading: isAuthenticating, isSuccess, data: authResponse }] = useAuthenticateConnectorMutation();
  const [getConnectors] = useLazyGetDistributionChannelConnectorsQuery();
  const style = concatClassNames('bg-white-100 overflow-hidden shadow rounded-lg flex flex-col flex-auto');
  useEffect(() => {
    if (isSuccess && authResponse) {
      console.log(authResponse);
      const connectorId = authResponse.connector.accountConnectorId;
      if (connectorId) {
        const url = `${authResponse.authUrl}?id=${connectorId}&token=${authResponse.token}&targetOrigin=${location.origin + RouteConstants.cyclrAuthRedirect}`;
        (window as any).cyclrDeauthenticationCompleted = (success: boolean, w: Window) => {
          w.close.apply(w);
          delete (window as any).cyclrDeauthenticationCompleted;
          getConnectors({ workspaceId });
          dispatch(showToast({
            type: ToastType.SUCCESS,
            title: `Destination connected`,
            message: `Destination ${connector.name} was successfully connected`
          }));
        };
        (window as any).authWindow = popupCenter({
          url,
          title: 'Authenticate',
          w: 640,
          h: 640,
        });
      } else {
        dispatch(showToast({
          type: ToastType.ERROR,
          title: `Error - Could not connect destination ${connector.name}`,
        }));
      }
    }
  }, [isSuccess]);

  const openDeauthenticateConnectorModal = () => {
    openModal({
      renderContent: DeauthenticateConnectorModal,
      renderContentProps: {
        workspaceId,
        connector,
      },
      dismissable: true,
      fullWidth: false,
      fullHeight: false,
    });
  };

  return (
    <div className={onClick ? concatClassNames(style, 'cursor-pointer') : style} onClick={onClick}>
      <div className={classNames('content-center justify-center px-4 py-5 sm:px-6 flex space-x-3')}>
        <div>
          <div className='flex content-center justify-center'>
            <div className='w-16 h-16 rounded-lg flex flex-wrap mb-3'>
              <img src={icon} />
            </div>
          </div>
          <div className='flex content-center justify-center'>
            <div>
              <p className='text-base font-medium text-gray-900'>{name}</p>
              <p className='text-base font-small text-gray-900'>{description}</p>
            </div>
          </div>
          <div className='flex content-center justify-center mt-5'>
            {(!installed || !authenticated) &&
              (
                <div className="flex flex-col justify-center items-center">
                  <XCircleIcon className="text-red-500 h-10 w-10" />
                  <div className="flex">
                    <span className={'text-red-500'}>
                      Destination <b>disconnected</b>
                    </span>
                  </div>
                  <Button variant="confirm"
                    className="mt-10"
                    loading={isAuthenticating}
                    onClick={() => authenticateConnector({ workspaceId, connectorName: name, integrationPartner })}>
                    Connect
                    <ExternalLinkIcon className="h-6 w-6 ml-1" />
                  </Button>
                </div>
              )}
            {authenticated &&
              (
                <div className="flex flex-col justify-center items-center">
                  <CheckCircleIcon className="text-green-500 h-10 w-10" />
                  <div className="flex">
                    <span className={'text-green-500'}>
                      Desitination <b>connected</b>
                    </span>
                  </div>
                  <Button variant="transparent-red"
                    className="mt-10 text-red-100"
                    loading={false}
                    onClick={() => openDeauthenticateConnectorModal()}>
                    Disconnect
                    <TrashIcon className="h-6 w-6 ml-1" />
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

type DeauthenticateConntectorModalProps = { workspaceId: WorkspaceId; connector: DistributionChannelConnector };

function DeauthenticateConnectorModal({ connector, workspaceId, closeModal }: DeauthenticateConntectorModalProps & IModalContentProps) {
  const { t } = useTranslation("destinations");
  const [deauthenticateConnector, { isLoading, isSuccess }] = useDeauthenticateConnectorMutation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess) {
      closeModal();
      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('catalog.deleteModal.toast_title'),
          message: t('catalog.deleteModal.toast_message', { connector: connector.name }),
        })
      );
    }
  }, [isSuccess]);
  return (
    <div className='flex justify-center h-96 '>
      <div className='max-w-4xl my-auto text-left p-5'>
        <h1 className='text-lg text-black-100 font-semibold mb-5'>{t("catalog.deleteModal.title")}</h1>
        <p className='text-base mb-5'>
          {t("catalog.deleteModal.subtitle", { connector: connector.name })}
        </p>
        <Button icon={TrashIcon} variant='delete' onClick={() => deauthenticateConnector({ workspaceId, connectorName: connector.name, integrationPartner: connector.integrationPartner })} loading={isLoading} disabled={isLoading}>
          {t('catalog.deleteModal.button_title')}
        </Button>
      </div>
    </div>
  );
}
