import { audiencerateApi } from '../../audiencerate';
import { TableResponse } from '../types';
import { CampaignResponse, CreateCampaignQueryArgs, CreateCampaignRequestBody, GetCampaignArg, GetCampaignsArg } from './types';

export const campaignsApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    createCampaign: build.mutation<CampaignResponse, CreateCampaignRequestBody & CreateCampaignQueryArgs>({
      query: ({ workspaceId, ...body }) => ({
        url: `/v1/workspaces/${workspaceId}/campaigns`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Campaigns'],
    }),
    getCampaigns: build.query<TableResponse<CampaignResponse>, GetCampaignsArg>({
      query: ({ workspaceId, limit, offset, searchText: search }) => ({
        url: `/v1/workspaces/${workspaceId}/campaigns`,
        params: { limit, offset, search },
      }),
      providesTags: ['Campaigns'],
    }),
    getCampaign: build.query<CampaignResponse, GetCampaignArg>({
      query: ({ workspaceId, campaignId }) => ({
        url: `/v1/workspaces/${workspaceId}/campaigns/${campaignId}`,
      }),
      providesTags: ['Campaign'],
    }),
  }),
  overrideExisting: false,
});

export const { useCreateCampaignMutation, useGetCampaignsQuery, useLazyGetCampaignsQuery, useGetCampaignQuery } = campaignsApi;
