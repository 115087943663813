import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Loadable } from '../../../../../../../components/loaders';
import { useGetAudienceQuery } from '../../../../../../../services/endpoints/audiences';
import { mapAudienceExpressionToRules } from '../../../../../../../utils';
import { ExpressionConditionRender } from '../../../../../../audienceEdit/components';
import { useWorkspace } from '../../../../../../workspaces/hooks';
import { CampaignDetailsSection } from '../../common';

type RulesUsedSectionProps = {
  audienceId: string | undefined;
};

export const RulesUsedSection: FC<RulesUsedSectionProps> = ({ audienceId }) => {
  const { t } = useTranslation('audience_details');
  const workspace = useWorkspace();

  const { data: audience, isFetching: isAudienceFetching } = useGetAudienceQuery(
    {
      workspaceId: workspace.id,
      audienceId,
    },
    {
      skip: !audienceId,
    }
  );

  const rules = useMemo(() => mapAudienceExpressionToRules(audience?.expression), [audience?.expression]);

  return (
    <CampaignDetailsSection header='rulesUsed'>
      <Loadable isLoading={isAudienceFetching}>
        {rules.map((rule, index) => (
          <div key={rule.id} className='flex p-4 bg-white-100 shadow overflow-hidden sm:rounded-md'>
            <h6 className='text-sm text-gray-900 text-right w-28 mr-6'>
              {t(index === 0 ? 'sources_and_rules.rules.users_who' : 'sources_and_rules.rules.and')}
            </h6>
            <div className='flex flex-col space-y-2 w-full'>
              <div className='border rounded p-1 space-y-2'>
                {rule.expressions.map((expression, index) => (
                  <div key={index} className='flex p-3 items-center'>
                    {index > 0 && <h6 className='text-sm text-gray-900 text-right w-6 mr-2'>{t('sources_and_rules.rules.or')}</h6>}
                    <ExpressionConditionRender expression={expression} isViewMode={true} datasourceIds={audience.datasourceIds} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </Loadable>
    </CampaignDetailsSection>
  );
};
