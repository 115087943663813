import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CampaignArrowRightIcon from '../../../../../../../assets/icons/campaign-arrow-right.svg?react';
import { QuickStartItem, QuickStartStep } from '../../common';
import { useCampaignsValues } from '../../context';
import { StartNewButton, TotalUsersStep } from './parts';

export const RecapSection: FC = () => {
  const { t } = useTranslation();
  const { selectedConnectors, selectedAudience } = useCampaignsValues();

  if (selectedConnectors.length === 0 || !selectedAudience) {
    return null;
  }

  return (
    <QuickStartStep>
      <p className='capitalize-first font-medium text-sm'>{t('campaign:recapSectionTitle')}</p>
      <div className='flex gap-3 items-center'>
        <div className='flex flex-wrap gap-2 min-h-36 max-w-40 items-center'>
          {selectedConnectors.map((connector) => (
            <QuickStartItem
              key={`${connector.name}-recap`}
              icon={connector.icon}
              isConfigured
              isSelected
              classNames={{
                trigger: 'w-fit',
                outerCircle: 'size-[70px]',
                innerCircle: 'size-[50px]',
                icon: 'size-[40px] left-[5px] top-[5px]',
              }}
            />
          ))}
        </div>
        <CampaignArrowRightIcon className='opacity-50 me-2' />
        <TotalUsersStep />
        <CampaignArrowRightIcon className='me-2' />
        <StartNewButton />
      </div>
    </QuickStartStep>
  );
};
