import { TraitDataType } from './traits';

export type AudienceSyncScheduleType = 'REALTIME' | 'SCHEDULED' | 'MANUAL';

export type BaseAudience = {
  id: string;
  name: string;
  extended: boolean;
  scheduleType: AudienceSyncScheduleType;
  scheduleConfiguration?: AudienceSyncSchedule;
  createdOn: string;
};

export type SyncScheduleUnit = 'HOURS' | 'DAYS' | 'WEEKS' | 'MONTHS';

export const WeekDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'] as const;
export const WeekDayShortNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as const;
export type WeekDay = (typeof WeekDays)[number];

export type AudienceSyncRealtimeSchedule = Record<string, never>;

export type AudienceSyncTimeBasedSchedule = {
  emailNotification: boolean;
  createSnapshotOnRefresh: boolean;
  startsOn: string;
  repeatsEvery: number;
  unit: SyncScheduleUnit;
  weekDays?: WeekDay[];
  dayOfMonth?: number;
};

export type AudienceSyncManualSchedule = {
  emailNotification: boolean;
  createSnapshotOnRefresh: boolean;
};

export type AudienceSyncSchedule = AudienceSyncRealtimeSchedule | AudienceSyncTimeBasedSchedule | AudienceSyncManualSchedule;

export type Audience = {
  datasourceIds: string[];
  matchingTrait: string;
  expression: Record<string, Expression>;
  scheduleType: AudienceSyncScheduleType;
  scheduleConfiguration: AudienceSyncSchedule;
} & BaseAudience;

export type Expression = Record<string, ExpressionCondition>;

export type ExpressionCondition = {
  type: 'MEMBERSHIP' | 'ACTION' | 'PROPERTY';
  dataSourceRuleId?: string;
  occurrences?: {
    comparisonOp: 'EXACTLY' | 'AT_MOST' | 'AT_LEAST' | 'NEVER';
    times?: number;
  };
  temporalHorizon?: {
    type?: 'WITHIN' | 'IN_BETWEEN';
    from?: string;
    to?: string;
    value?: number;
    unit?: 'DAY' | 'WEEK' | 'MONTH';
  };
  property?: string;
  trait?: boolean;
  comparisonOp?: string;
  dataType?: TraitDataType;
  membership?: 'IN' | 'NOT_IN';
  audienceIds?: string[];
  value?: string;
  kind?: 'EVENT' | 'VISIT';
  customEventName?: string;
};

export type AudienceEstimate = {
  size: number;
};

export type BulkAudienceEstimate = {
  audienceId: string;
  size: number;
};
