import { FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { FileDataSourceColumnMap, FileDataSourceConfigurationCompleted, FileDataSourceCSVHeader, FileDataSourceUploadForm, FileDataType } from '../..';
import { Step } from '../../../../../components/steppers';
import { DataSourceType } from '../../../../../models/dataSource';
import { GroupedTraits } from '../../../../../models/traits';
import { RootState } from '../../../../../reducers';
import { EFileSteps } from '../../../constants';
import { TColumnItem } from '../../../types';
import { FileDataSourceUploadFormModel } from './FileDataSourceUploadForm';
import { FileStepContentWrapper } from './FileStepContentWrapper';

interface IFileDataSourceContentProps {
  dataSourceType: DataSourceType;
  groupedTraits?: GroupedTraits;
  step?: Step;
  goNext?: () => void;
  formRef: React.RefObject<FormikProps<FileDataSourceUploadFormModel>>;
  columnMapFormRef: React.RefObject<FormikProps<TColumnItem[]>>;
  loading: boolean;
  closeModal: () => void;
}

const FileDataSourceContent: FunctionComponent<IFileDataSourceContentProps> = ({
  dataSourceType,
  groupedTraits,
  step,
  formRef,
  goNext,
  loading,
  columnMapFormRef,
  closeModal,
}) => {
  // switch base on data source type category here!
  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);

  const handleSubmit = async (form: FileDataSourceUploadFormModel) => {
    // TODO: Refactor to move some logic here
  };

  switch (step?.id) {
    case EFileSteps.NAME_AND_FILE:
      return (
        <FileStepContentWrapper>
          <FileDataSourceUploadForm formRef={formRef} onSubmit={handleSubmit} dataSource={{ ...fileDataSource, type: dataSourceType }} />
        </FileStepContentWrapper>
      );
    case EFileSteps.HEADER:
      return (
        <FileStepContentWrapper>
          <FileDataSourceCSVHeader />
        </FileStepContentWrapper>
      );
    case EFileSteps.DATA_TYPE:
      return (
        <FileStepContentWrapper>
          <FileDataType />
        </FileStepContentWrapper>
      );
    case EFileSteps.MAP_COLUMNS:
      return (
        <FileStepContentWrapper>
          <FileDataSourceColumnMap columnMapFormRef={columnMapFormRef} groupedTraits={groupedTraits} />
        </FileStepContentWrapper>
      );
    case EFileSteps.CONFIRMATION:
      return (
        <FileStepContentWrapper>
          <FileDataSourceConfigurationCompleted closeModal={closeModal} />
        </FileStepContentWrapper>
      );
    default:
      return null;
  }
};

export default FileDataSourceContent;
