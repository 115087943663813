import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, TextArea } from '../../../../../../../components/inputs';
import { QuickStartStep } from '../../common';
import { useCampaignsActions, useCampaignsValues } from '../../context';

export const CampaignSection: FC = () => {
  const { t } = useTranslation('campaign');
  const { name, description } = useCampaignsValues();
  const { setName, setDescription } = useCampaignsActions();

  return (
    <QuickStartStep>
      <p className='capitalize-first font-bold text-2xl'>{t('newCampaign')}</p>
      <div className='gap-2 flex flex-col'>
        <Input className='w-1/3' value={name} onChange={(e) => setName(e.target.value)} placeholder={t('namePlaceholder')} />
        <TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={t('descriptionPlaceholder')}
          className='placeholder:whitespace-pre-wrap min-h-24 w-11/12'
          wrapperClassName='m-0'
        />
      </div>
    </QuickStartStep>
  );
};
