import { FC, useEffect } from 'react';
import { Button } from '../../../../../../../components/buttons';
import { SpinnerIcon } from '../../../../../../../components/icons';
import { DistributionChannelConnector } from '../../../../../../../models/distributionChannels';
import { ToastType } from '../../../../../../../models/toast';
import { useAppDispatch } from '../../../../../../../reducers';
import { useAuthenticateConnectorMutation, useLazyGetDistributionChannelConnectorsQuery } from '../../../../../../../services/endpoints/distributionChannels';
import { popupCenter } from '../../../../../../../utils';
import RouteConstants from '../../../../../../router/RouteConstants';
import { showToast } from '../../../../../../toasts/toastsSlice';
import { useWorkspace } from '../../../../../../workspaces/hooks';
import { QuickStartItem } from '../../common';
import { useCampaignsActions, useCampaignsValues } from '../../context';

type DestinationQuickStartItemProps = {
  connector: DistributionChannelConnector;
  selectedDisconnectedConnector: DistributionChannelConnector | null;
  onSelectedDisconnectedConnector: (connector: DistributionChannelConnector | null) => void;
};

export const DestinationQuickStartItem: FC<DestinationQuickStartItemProps> = ({
  connector,
  selectedDisconnectedConnector,
  onSelectedDisconnectedConnector,
}) => {
  const dispatch = useAppDispatch();
  const { id: workspaceId } = useWorkspace();
  const { name, icon, installed, authenticated, integrationPartner } = connector;
  const [authenticateConnector, { isSuccess, data: authResponse, isLoading }] = useAuthenticateConnectorMutation();
  const [getConnectors] = useLazyGetDistributionChannelConnectorsQuery();

  const { selectedConnectors } = useCampaignsValues();
  const { toggleConnector } = useCampaignsActions();

  useEffect(() => {
    if (isSuccess && authResponse) {
      console.log(authResponse);
      const connectorId = authResponse.connector.accountConnectorId;
      if (connectorId) {
        const url = `${authResponse.authUrl}?id=${connectorId}&token=${authResponse.token}&targetOrigin=${location.origin + RouteConstants.cyclrAuthRedirect}`;
        (window as any).cyclrDeauthenticationCompleted = (success: boolean, w: Window) => {
          w.close.apply(w);
          delete (window as any).cyclrDeauthenticationCompleted;
          getConnectors({ workspaceId });
          dispatch(
            showToast({
              type: ToastType.SUCCESS,
              title: `Destination connected`,
              message: `Destination ${connector.name} was successfully connected`,
            })
          );
        };
        (window as any).authWindow = popupCenter({
          url,
          title: 'Authenticate',
          w: 640,
          h: 640,
        });
      } else {
        dispatch(
          showToast({
            type: ToastType.ERROR,
            title: `Error - Could not connect destination ${connector.name}`,
          })
        );
      }
    }
  }, [isSuccess]);

  const isConfigured = installed && authenticated;
  // const isConfigured = true;
  const isSelected = isConfigured ? selectedConnectors.includes(connector) : selectedDisconnectedConnector === connector;

  const handleClick = () => {
    if (isConfigured) {
      toggleConnector(connector);
    } else {
      onSelectedDisconnectedConnector(connector);
    }
  };

  return (
    <div className='flex flex-col gap-2 items-center'>
      <QuickStartItem isSelected={isSelected} onClick={isLoading ? undefined : handleClick} isConfigured={isConfigured} title={name} icon={icon} />
      {isLoading ? (
        <SpinnerIcon className='size-5' loading />
      ) : (
        <Button
          disabled={isConfigured || !isSelected}
          variant='light'
          className='!text-[10px] !px-2 !py-0.5 leading-3 enabled:opacity-100 disabled:!opacity-0 motion-safe:transition-opacity motion-safe:duration-300 motion-reduce:transition-none disabled:!cursor-default '
          onClick={() => authenticateConnector({ workspaceId, connectorName: name, integrationPartner })}
        >
          Connect
        </Button>
      )}
    </div>
  );
};
