import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type QuickStartStepProps = {
  disabled?: boolean;
  className?: string;
} & PropsWithChildren;

export const QuickStartStep: FC<QuickStartStepProps> = ({ disabled, className, children }) => (
  <article aria-disabled={disabled} className={twMerge('group aria-disabled:cursor-not-allowed flex flex-col gap-5 py-4 first:pt-0', className)}>
    <div className='contents group-aria-disabled:pointer-events-none group-aria-disabled:*:opacity-50 *:motion-safe:transition-opacity *:motion-safe:duration-300 *:motion-reduce:transition-none'>
      {children}
    </div>
  </article>
);
