import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

type QuickStartItemProps = {
  icon: string;
  isConfigured?: boolean;
  isSelected?: boolean;
  onClick?: VoidFunction;
  title?: string;
  classNames?: {
    trigger?: string;
    outerCircle?: string;
    innerCircle?: string;
    icon?: string;
  };
};

export const QuickStartItem: FC<QuickStartItemProps> = ({ icon, isConfigured = false, isSelected = false, onClick, title, classNames }) => {
  const isDisabled = !onClick;

  return (
    <button disabled={isDisabled} onClick={onClick} className={twMerge('flex group flex-col gap-1 items-center w-28', classNames?.trigger)} tabIndex={-1}>
      <div
        data-selected={isSelected}
        data-configured={isConfigured}
        className={twMerge(
          'relative size-[90px] rounded-full motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none bg-gray-250',
          'data-[configured=true]:bg-gray-250 group-hover:group-enabled:data-[selected=false]:data-[configured=true]:bg-green-350/50 group-hover:group-enabled:data-[selected=false]:data-[configured=false]:bg-orange/50',
          'data-[selected=true]:data-[configured=true]:bg-green-350 data-[selected=true]:data-[configured=false]:bg-orange',
          classNames?.outerCircle
        )}
      >
        <div className={twMerge('relative bg-white-100 size-[70px] rounded-full left-[10px] top-[10px]', classNames?.innerCircle)}>
          <img src={icon} className={twMerge('size-[50px] rounded-full absolute left-[10px] top-[10px]', classNames?.icon)} />
        </div>
      </div>
      {title ? <p className='capitalize text-sm text-black-100'>{title}</p> : null}
    </button>
  );
};
