import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { logout, storeToken } from '../features/auth/authSlice';
import { RootState } from '../reducers';
import { Auth0Service } from './auth0Service';

export const API_URL = import.meta.env.VITE_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // Check on 403 is a workaround for token being "invalid" because new api is deployed
  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    // token is resolved as empty string if Service isn't ready
    const token = await Auth0Service.getAccessTokenSilently({ cacheMode: 'off' });

    if (token) {
      api.dispatch(storeToken(token));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      // should add call to logout action from Auth0Service I think
      api.dispatch(logout());
    }
  }

  return result;
};

export const audiencerateApi = createApi({
  reducerPath: 'audiencerateApi',
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 1,
  tagTypes: [
    'userInfo',
    'AllAudiences',
    'DataSources',
    'DataSource',
    'Workspace',
    'Workspaces',
    'Audiences',
    'Audience',
    'ExtendedAudiences',
    'Traits',
    'Rules',
    'DistributionChannel',
    'DistributionChannelConnector',
    'DistributionChannels',
    'Company',
    'CompanyInvites',
    'CompanyUsers',
    'CompanyWorkspaces',
    'WorkspacePublicKey',
    'WorkspaceInvites',
    'WorkspaceUsers',
    'Campaigns',
    'Campaign',
  ],
  endpoints: () => ({}),
});
