import { rest } from 'lodash';
import { FC, ReactNode, useCallback, useState } from 'react';
import { Loadable } from '../../../../../../../../../components/loaders';
import { SimpleTable } from '../../../../../../../../../components/tables';
import Paginated from '../../../../../../../../../components/tables/pagination/Paginated';
import { Audience } from '../../../../../../../../../models/audiences';
import { useLazyGetAudiencesQuery } from '../../../../../../../../../services/endpoints/audiences';
import { setRegularPagination } from '../../../../../../../../audiences/audiencesSlice';
import { useWorkspace } from '../../../../../../../../workspaces/hooks';
import { QUICK_START_AUDIENCES_TABLE_COLUMNS } from './constants';
import { QuickStartAudienceRow } from './QuickStartAudienceRow';

export const QuickStartAudiencesTable: FC = () => {
  const workspace = useWorkspace();

  const [getAudiences, { data, isFetching, isLoading }] = useLazyGetAudiencesQuery();
  const [audiences, setAudiences] = useState<Audience[]>([]);

  const queryCallback = useCallback(
    (pageNo: number, pageSize: number) =>
      getAudiences({
        workspaceId: workspace.id,
        offset: pageNo * pageSize,
        limit: pageSize,
      }),
    []
  );

  const renderContent = (pager: ReactNode) => {
    return (
      <Loadable isLoading={isLoading}>
        <SimpleTable {...rest}>
          <colgroup>
            <col style={{ width: '30px' }} />
            <col style={{ width: '200px' }} />
            <col style={{ width: '150px' }} />
            <col style={{ width: '150px' }} />
            <col style={{ width: '150px' }} />
          </colgroup>
          <thead>
            <tr>
              {QUICK_START_AUDIENCES_TABLE_COLUMNS.map((column) => (
                <th
                  key={column.label}
                  scope='col'
                  className={`px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0 ${column.className}`}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{audiences?.map((audience) => <QuickStartAudienceRow key={audience.id} audience={audience} />)}</tbody>
        </SimpleTable>
        {pager}
      </Loadable>
    );
  };

  return (
    <Paginated
      stateSelector={(state) => state.audiences.ui.regular.pagination}
      stateUpdater={setRegularPagination}
      query={queryCallback}
      queryResponse={data ? data.items : undefined}
      setItems={setAudiences}
      querying={isFetching}
      wrapperClassNames='mb-0'
    >
      {renderContent}
    </Paginated>
  );
};
