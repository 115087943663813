export enum ECodeSteps {
  SOURCE_DETAILS = 1,
  TRACK_ACTIVITY = 2,
  INSTALL_SNIPPET = 3,
  CHECK_INSTALLATION = 4,
}

export enum ECrmSteps {
  SOURCE_DETAILS = 1,
  UPDATE_PARAMETERS = 2,
  UPDATE_FIELDS = 3,
  CHECK_INSTALLATION = 4,
}

export enum EFileSteps {
  NAME_AND_FILE = 1,
  HEADER = 2,
  DATA_TYPE = 3,
  MAP_COLUMNS = 4,
  CONFIRMATION = 5,
}

export enum AmazonS3Steps {
  NAME_AND_BUCKET = 1,
  HEADER = 2,
  DATA_TYPE = 3,
  MAP_COLUMNS = 4,
  CONFIRMATION = 5,
}

export const CONSENT_TRAIT = 'consent_trait';

export const CUSTOMER = 'CUSTOMER';
export const EVENT = 'EVENT';
