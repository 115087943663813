import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationState } from '../../models/pagination';
import { CampaignsTopbarTab } from '../../models/tabs';

type ListState = {
  pagination: PaginationState;
  searchText: string;
};

type AudiencesState = {
  ui: {
    tabs: CampaignsTopbarTab[];
    regular: ListState;
    extended: ListState;
  };
};

export const initialState: AudiencesState = {
  ui: {
    tabs: [
      { id: 'campaign.regular', current: true },
      { id: 'campaign.extended', current: false },
    ],
    regular: {
      pagination: {
        pageNo: 0,
        pageSize: 0,
      },
      searchText: '',
    },
    extended: {
      pagination: {
        pageNo: 0,
        pageSize: 0,
      },
      searchText: '',
    },
  },
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    reset: () => initialState,
    setCampaignTabs: (state, action: PayloadAction<CampaignsTopbarTab[]>) => ({
      ...state,
      ui: {
        ...state.ui,
        tabs: action.payload,
      },
    }),
    setRegularPagination: (state, action: PayloadAction<PaginationState>) => ({
      ...state,
      ui: {
        ...state.ui,
        regular: {
          ...state.ui.regular,
          pagination: action.payload,
        },
      },
    }),
    setRegularSearchText: (state, action: PayloadAction<string>) => ({
      ...state,
      ui: {
        ...state.ui,
        regular: {
          ...state.ui.regular,
          searchText: action.payload,
        },
      },
    }),
    setExtendedPagination: (state, action: PayloadAction<PaginationState>) => ({
      ...state,
      ui: {
        ...state.ui,
        extended: {
          ...state.ui.extended,
          pagination: action.payload,
        },
      },
    }),
    setExtendedSearchText: (state, action: PayloadAction<string>) => ({
      ...state,
      ui: {
        ...state.ui,
        extended: {
          ...state.ui.extended,
          searchText: action.payload,
        },
      },
    }),
  },
});

export const { reset, setCampaignTabs, setRegularPagination, setRegularSearchText, setExtendedPagination, setExtendedSearchText } = campaignsSlice.actions;

export default campaignsSlice.reducer;
