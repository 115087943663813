import { FunctionComponent } from 'react';
import {
  DataSource,
  DataSourceAmazonS3Config,
  DataSourceCodeConfig,
  DataSourceFileConfig,
  DataSourceIDEnum,
  EDataSourceCategory,
  TDataSource,
} from '../../models/dataSource';
import { ETABS, TabType } from '../../models/tabs';
import { CodeDataSourceData, DataSourceOverview, DataSourceSettings, FileDataSourceData } from './components';
import { AmazonS3DataSourceData } from './components/tabs/AmazonS3DataSourceData';

interface ITabContentProps {
  tabs: TabType[];
  selectedDataSource?: DataSource;
}

const DataSourceTabContent: FunctionComponent<ITabContentProps> = ({ tabs, selectedDataSource }) => {
  const currentTab = tabs.find((tab) => tab.current);

  switch (currentTab?.id) {
    case ETABS.OVERVIEW:
      return <DataSourceOverview dataSource={selectedDataSource} />;

    case ETABS.DATA:
      switch (selectedDataSource?.type.category) {
        case EDataSourceCategory.FILE: {
          if (selectedDataSource.type.id === DataSourceIDEnum.AmazonS3) {
            return <AmazonS3DataSourceData dataSource={selectedDataSource as TDataSource<DataSourceAmazonS3Config>} />;
          }

          return <FileDataSourceData dataSource={selectedDataSource as TDataSource<DataSourceFileConfig>} />;
        }

        case EDataSourceCategory.CODE:
          return <CodeDataSourceData dataSource={selectedDataSource as TDataSource<DataSourceCodeConfig>} />;

        default:
          return null;
      }

    case ETABS.SETTINGS:
      return <DataSourceSettings dataSource={selectedDataSource} />;

    default:
      return null;
  }
};

export default DataSourceTabContent;
