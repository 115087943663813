import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../../../components/buttons';
import { SimpleList } from '../../../../../../../components/lists';
import { Loadable } from '../../../../../../../components/loaders';
import { DataSource } from '../../../../../../../models/dataSource';
import { useGetDataSourcesByIdsQuery } from '../../../../../../../services/dataSources';
import { useGetAudienceQuery } from '../../../../../../../services/endpoints/audiences';
import { useWorkspace } from '../../../../../../workspaces/hooks';
import { CampaignDetailsSection } from '../../common';

type DatasourcesUsedSectionProps = {
  audienceId: string | undefined;
};

export const DatasourcesUsedSection: FC<DatasourcesUsedSectionProps> = ({ audienceId }) => {
  const { t } = useTranslation('audience_details');
  const history = useHistory();
  const workspace = useWorkspace();

  const { data: audience, isFetching: isAudienceFetching } = useGetAudienceQuery(
    {
      workspaceId: workspace.id,
      audienceId,
    },
    {
      skip: !audienceId,
    }
  );

  const { data: dataSources, isFetching: isDataSourcesFetching } = useGetDataSourcesByIdsQuery(
    {
      workspaceId: workspace.id,
      datasourceIds: audience?.datasourceIds,
    },
    {
      skip: !audience,
    }
  );

  const handleGoToSourceClick = (dataSource: DataSource) => {
    history.push(`/data-sources/${dataSource.id}`);
  };

  return (
    <CampaignDetailsSection header='datasourceUsed'>
      <Loadable isLoading={isAudienceFetching || isDataSourcesFetching}>
        <SimpleList<DataSource>
          items={dataSources}
          rightElement={(item: DataSource) => (
            <Button variant='secondary' onClick={() => handleGoToSourceClick(item)}>
              {t('sources_and_rules.go_to_source')}
            </Button>
          )}
        />
      </Loadable>
    </CampaignDetailsSection>
  );
};
