import { FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseAudience } from '../../../../models/audiences';

type BaseAudienceRowProps = {
  id: string;
  audience: BaseAudience;
} & PropsWithChildren;

export const BaseAudienceRow: FC<BaseAudienceRowProps> = ({ id, audience, children }) => {
  const history = useHistory();

  const onAudienceRowClick = () => {
    if (audience.extended) {
      history.push(`/extended-audiences/${audience.id}`);
    } else {
      history.push(`/audiences/${audience.id}`);
    }
  };

  return (
    <tr id={id} className='bg-white-100 border-b border-gray-200 cursor-pointer hover:bg-gray-50 last:border-0' onClick={onAudienceRowClick}>
      {children}
    </tr>
  );
};
