import { omit } from 'lodash';
import React, { FunctionComponent } from 'react';
import { twMerge } from 'tailwind-merge';

interface IInputProps {
  id?: string;
  type?: 'text' | 'number' | 'email' | 'password' | 'file' | 'submit';
  label?: string;
  className?: string;
  error?: string;
  placeholder?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  extraInfo?: string;
  isViewMode?: boolean;
  labelClassName?: string;
}

type TInputType = IInputProps & React.ComponentProps<'input'>;

const Input: FunctionComponent<TInputType> = ({ id, type = 'text', name, label, className, onChange, error, placeholder, extraInfo, isViewMode, labelClassName, ...rest }) => {
  let inputStyle =
    'inline-flex w-full px-4 sm:text-sm border border-gray-300 focus:border-blue-600 focus:border focus:outline-none rounded-md placeholder-gray-400';

  if (rest.icon) {
    inputStyle = twMerge(inputStyle, 'pl-10');
  }

  if (error) {
    inputStyle = twMerge(inputStyle, 'border-red-500 hover:border-red-500 focus:border-red-500 outline-none');
  }

  if (rest.disabled) {
    inputStyle = twMerge(inputStyle, 'bg-gray-200 text-gray-500');
  }

  const viewModeProps = { type };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className='inline-flex flex-col w-full text-left'>
      {label && (
        <label htmlFor={name} className={twMerge('block text-sm font-medium text-gray-700 py-2', labelClassName)}>
          {label}
        </label>
      )}
      <div className='relative rounded-md w-auto'>
        {rest.icon && (
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <rest.icon className='mr-4 flex-shrink-0 h-6 w-6 text-gray-500' />
          </div>
        )}
        {isViewMode ? (
          <div {...viewModeProps} className={className ? twMerge(inputStyle, className) : inputStyle}>
            {rest.value}
          </div>
        ) : (
          <input
            {...omit(rest, 'icon')}
            id={id}
            name={name}
            type={type}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={rest.disabled || isViewMode}
            className={className ? twMerge(inputStyle, className) : inputStyle}
          />
        )}
      </div>
      {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
      {extraInfo && !error && <p className='mt-2 text-sm text-gray-500'>{extraInfo}</p>}
    </div>
  );
};

export default Input;
