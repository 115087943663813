import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Step } from '../../../../../../components/steppers';
import { DataSourceAmazonS3Config, DataSourceType, TDataSource } from '../../../../../../models/dataSource';
import { GroupedTraits } from '../../../../../../models/traits';
import { RootState, useAppDispatch } from '../../../../../../reducers';
import { getS3FilePreviewKey, useGetS3FilePreviewMutation } from '../../../../../../services/endpoints/amazonS3';
import { updateFileData } from '../../../../../fileUploading/fileUploadingSlice';
import { AmazonS3Steps } from '../../../../constants';
import { TColumnItem } from '../../../../types';
import FileDataSourceColumnMap from '../../file/FileDataSourceColumnMap';
import FileDataType from '../../file/FileDataType';
import { FileStepContentWrapper } from '../../file/FileStepContentWrapper';
import { AmazonS3ConfigurationCompleted } from './AmazonS3ConfigurationCompleted';
import AmazonS3DataSourceCSVHeader from './AmazonS3DataSourceCSVHeader';
import { AmazonS3FormModel, NameAndBucketStep } from './NameAndBucketStep';

type AmazonS3DataSourceContentProps = {
  dataSourceType: DataSourceType;
  groupedTraits?: GroupedTraits;
  step?: Step;
  goNext?: () => void;
  formRef: React.RefObject<FormikProps<AmazonS3FormModel>>;
  columnMapFormRef: React.RefObject<FormikProps<TColumnItem[]>>;
  loading: boolean;
};

const CSV_ROWS_NUMBER = 5;

export const AmazonS3DataSourceContent: FC<AmazonS3DataSourceContentProps> = ({ groupedTraits, step, formRef, columnMapFormRef }) => {
  const dispatch = useAppDispatch();
  const dataSource = useSelector((state: RootState) => state.dataSources.edit?.dataSource) as Partial<TDataSource<DataSourceAmazonS3Config>> | undefined;

  const [getS3FilePreview] = useGetS3FilePreviewMutation({
    fixedCacheKey: getS3FilePreviewKey,
  });

  const handleSubmit = async (form: AmazonS3FormModel) => {
    const response = await getS3FilePreview({ ...form.configuration, limit: CSV_ROWS_NUMBER });

    if ('data' in response) {
      const matrix = response.data.map((row) => row.split(','));

      dispatch(updateFileData({ preview: matrix?.slice(0, CSV_ROWS_NUMBER), header: matrix[0], isDefaultHeader: true }));
    }
  };

  switch (step?.id) {
    case AmazonS3Steps.NAME_AND_BUCKET:
      return <NameAndBucketStep formRef={formRef} onSubmit={handleSubmit} dataSource={dataSource} />;
    case AmazonS3Steps.HEADER:
      return (
        <FileStepContentWrapper>
          <AmazonS3DataSourceCSVHeader />
        </FileStepContentWrapper>
      );
    case AmazonS3Steps.DATA_TYPE:
      return (
        <FileStepContentWrapper>
          <FileDataType />
        </FileStepContentWrapper>
      );
    case AmazonS3Steps.MAP_COLUMNS:
      return (
        <FileStepContentWrapper>
          <FileDataSourceColumnMap columnMapFormRef={columnMapFormRef} groupedTraits={groupedTraits} />
        </FileStepContentWrapper>
      );
    case AmazonS3Steps.CONFIRMATION:
      return (
        <FileStepContentWrapper>
          <AmazonS3ConfigurationCompleted />
        </FileStepContentWrapper>
      );
    default:
      return null;
  }
};
