import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ISimpleTableProps, SimpleTable } from '../../../../../../../components/tables';
import { CampaignRow, CAMPAIGNS_TABLE_COLUMNS } from './columns';
import { CampaignsTableItem } from './types';

type CampaignsTableProps = ISimpleTableProps & {
  campaigns: CampaignsTableItem[];
};

export const CampaignsTable: FC<CampaignsTableProps> = ({ campaigns, ...rest }) => {
  const { t } = useTranslation('campaign');

  return (
    <SimpleTable {...rest}>
      <colgroup>
        <col style={{ width: '30px', minWidth: '30px' }} />
        <col style={{ width: '100px', minWidth: '100px' }} />
        <col style={{ width: '400px', minWidth: '400px' }} />
        <col style={{ width: '150px', minWidth: '50px' }} />
        <col style={{ width: '100px', minWidth: '100px' }} />
      </colgroup>
      <thead>
        <tr>
          {CAMPAIGNS_TABLE_COLUMNS.map((heading) => (
            <th key={heading} className={`p-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0`}>
              {heading ? t(heading) : null}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{campaigns?.map((campaign) => <CampaignRow key={campaign.id} campaign={campaign} />)}</tbody>
    </SimpleTable>
  );
};
