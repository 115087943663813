import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoardUserIcon from '../../../../assets/icons/board-user.svg?react';
import ClockIcon from '../../../../assets/icons/clock.svg?react';
import PlusIcon from '../../../../assets/icons/plus.svg?react';
import UserGroupIcon from '../../../../assets/icons/user-group.svg?react';
import { Button } from '../../../../components/buttons';
import { SpinnerIcon } from '../../../../components/icons';
import { DistributionChannelAdvancedParameter, DistributionChannelContent } from '../../../../models/distributionChannels';
import { useGetEstimateQuery } from '../../../../services/endpoints/audiences';
import { usePatchDistributionChannelMutation } from '../../../../services/endpoints/distributionChannels';
import { useWorkspace } from '../../../workspaces/hooks';

interface IDestinationStepSyncProps {
  distributionChannelContent: DistributionChannelContent;
  isExtendedAudience: boolean;
  closeModal: () => void;
}

const DestinationStepSync: FunctionComponent<IDestinationStepSyncProps> = ({ distributionChannelContent, isExtendedAudience, closeModal }) => {
  const { t } = useTranslation('destinations');
  const workspace = useWorkspace();

  const [patchDistributionChannel, { isLoading: isActiveLoading, isSuccess: isActiveSuccess, isError: isActiveError, data: activeData, error: activeError }] =
    usePatchDistributionChannelMutation();

  const { data: estimateData, isLoading: isEstimateLoading } = useGetEstimateQuery({
    audienceId: distributionChannelContent.distributionChannel.audienceId,
    workspaceId: workspace.id,
  });

  const [advancedSettings, setAdvancedSettings] = useState<DistributionChannelAdvancedParameter>({
    activate: true,
    allowAddUsers: true,
    allowRemoveUsers: true,
    allowUpdateUsers: true,
  });

  useEffect(() => {
    if (isActiveSuccess) {
      closeModal();
    }
  }, [isActiveSuccess]);

  const syncEvent = () => {
    patchDistributionChannel({
      workspaceId: workspace.id,
      audienceId: distributionChannelContent.distributionChannel.audienceId,
      distributionChannelId: distributionChannelContent.distributionChannel.id,
      parameters: advancedSettings,
    });
  };

  return (
    <Fragment>
      <div className={'p-6 flex-grow'}>
        <h3 className={'text-left font-bold'}>{t('edit.step.sync.header')}</h3>
        <p className={'text-left'}>{t('edit.step.sync.description')}</p>
        <div className={'border-solid border border-gray-200 rounded-lg h-auto mt-6 text-left font-medium'}>
          <div className='p-4 bg-gray-50'>
            <div className='flex items-start justify-between'>
              {t('edit.step.sync.sync_with', {
                audienceName: distributionChannelContent.distributionChannel.audience.name,
                destinationName: distributionChannelContent.integrationsResult.cyclr.cycle.Name,
              })}
            </div>
          </div>

          <div className={'px-4 py-5'}>
            <ul>
              <li className={'text-base flex items-center text-gray-700 mb-2'}>
                <PlusIcon className={'h-4 mr-2 text-gray-600'} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('edit.step.sync.create_segment', {
                      destinationName: distributionChannelContent.distributionChannel.destination,
                      audienceName: distributionChannelContent.distributionChannel.audience.name,
                    }),
                  }}
                ></span>
              </li>
              <li className={'text-base flex items-center text-gray-700 mb-2'}>
                <UserGroupIcon className={'h-4 mr-2 text-gray-600'} />
                {isEstimateLoading ? (
                  <div className={'w-3 h-full flex justify-center items-center rounded relative cursor-not-allowed'}>
                    <SpinnerIcon className='h-3 w-3' loading />
                  </div>
                ) : (
                  estimateData?.size + ' users'
                )}
              </li>
              <li className={'text-base flex items-center text-gray-700 mb-2'}>
                <BoardUserIcon className={'h-4 mr-2 text-gray-600'} />
                {t('edit.step.sync.properties', { count: distributionChannelContent.integrationsResult.cyclr.action.fields.length })}
              </li>
              <li className={'text-base flex items-center text-gray-700'}>
                <ClockIcon className={'h-4 mr-2 text-gray-600'} />
                {t(`edit.step.sync.sync_${distributionChannelContent.distributionChannel.audience.scheduleType.toLowerCase()}` as any)}
              </li>
            </ul>
          </div>
        </div>

        {/* <div className={'border-solid border border-gray-200 rounded-lg h-auto mt-6 text-left font-medium px-4 py-5'}>
                    <h3 className={'font-bold'}>{t('edit.step.sync.advanced_settings')}</h3>
                    <div className={'flex'}>
                        <div className={'w-1/2'}>
                            <Checkbox
                                label={t('edit.step.sync.add_new_users_label')}
                                description={t('edit.step.sync.add_new_users_description', { destinationName: distributionChannelContent.integrationsResult.cyclr.cycle.Name })}
                                defaultChecked={advancedSettings.allowAddUsers}
                                onChange={(event) =>
                                    setAdvancedSettings({
                                        ...advancedSettings,
                                        allowAddUsers: event.target.checked,
                                    })
                                }
                            />
                        </div>
                        <div className={'w-1/2'}>
                            <Checkbox
                                label={t('edit.step.sync.remove_users_label')}
                                description={t('edit.step.sync.remove_users_description', { destinationName: distributionChannelContent.integrationsResult.cyclr.cycle.Name })}
                                defaultChecked={advancedSettings.allowRemoveUsers}
                                onChange={(event) =>
                                    setAdvancedSettings({
                                        ...advancedSettings,
                                        allowRemoveUsers: event.target.checked,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div> */}
      </div>
      <div className='flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg'>
        <Button onClick={syncEvent} loading={isActiveLoading}>
          {t('edit.step.complete')}
        </Button>
      </div>
    </Fragment>
  );
};

export default DestinationStepSync;
