import { ComponentPropsWithoutRef, FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type CampaignDetailsSectionProps = { header?: Parameters<TFunction<'campaign', undefined>>[0] } & ComponentPropsWithoutRef<'article'>;

export const CampaignDetailsSection: FC<CampaignDetailsSectionProps> = ({ header, className, children, ...rest }) => {
  const { t } = useTranslation('campaign');

  return (
    <article className={twMerge('flex flex-col gap-2', className)} {...rest}>
      {header ? <h2 className='text-gray-900 text-lg font-bold'>{t(header)}</h2> : null}
      {children}
    </article>
  );
};
