import { FC, useMemo } from 'react';
import { Loadable } from '../../../../../../../components/loaders';
import { useGetEstimateQuery } from '../../../../../../../services/endpoints/audiences';
import { useGetAudienceDistributionChannelsQuery } from '../../../../../../../services/endpoints/distributionChannels';
import { useWorkspace } from '../../../../../../workspaces/hooks';
import { CampaignDetailsSection } from '../../common';
import DestinationTable from './DestinationsTable';
import { DestinationsTableItem } from './types';

type ConnectedDestinationsSectionProps = {
  audienceId: string | undefined;
};

export const ConnectedDestinationsSection: FC<ConnectedDestinationsSectionProps> = ({ audienceId }) => {
  const workspace = useWorkspace();

  const { data: destinations, isFetching: isDestinationsFetching } = useGetAudienceDistributionChannelsQuery(
    {
      workspaceId: workspace.id,
      audienceId,
    },
    {
      skip: !audienceId,
    }
  );

  const { data: estimate, isFetching: isEstimateFetching } = useGetEstimateQuery(
    {
      workspaceId: workspace.id,
      audienceId,
    },
    {
      skip: !audienceId,
    }
  );

  const tableData = useMemo(
    () =>
      destinations?.map(
        (destination) =>
          ({
            ...destination,
            ...estimate,
          }) satisfies DestinationsTableItem
      ) ?? [],
    [destinations, estimate]
  );

  return (
    <CampaignDetailsSection header='connectedDestinations'>
      <Loadable isLoading={isDestinationsFetching || isEstimateFetching}>
        <DestinationTable destinations={tableData} />
      </Loadable>
    </CampaignDetailsSection>
  );
};
