import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../../../../../components/buttons';
import { DistributionChannelConnector } from '../../../../../../../../../../../models/distributionChannels';
import { useCreateCampaignMutation } from '../../../../../../../../../../../services/endpoints/campaigns';
import {
  usePatchDistributionChannelMutation,
  useUpdateDistributionChannelFieldsMutation,
} from '../../../../../../../../../../../services/endpoints/distributionChannels';
import { useWorkspace } from '../../../../../../../../../../workspaces/hooks';
import { CampaignsValuesApi } from '../../../../../../context';
import { DestinationItemData } from './DestinationItemData';
import { CampaignContent } from './types';

export type CampaignTraitStepItem = CampaignContent & {
  selectedFields: { [key: number]: boolean };
  isUpdateSuccess?: boolean;
};

type CampaignTraitsStepProps = {
  initialData: CampaignContent[];
  campaignData: CampaignsValuesApi;
  closeModal: VoidFunction;
};

export const CampaignTraitsStep: FC<CampaignTraitsStepProps> = ({ initialData, campaignData, closeModal }) => {
  const workspace = useWorkspace();
  const { t } = useTranslation('campaign');

  const [data, setData] = useState<CampaignTraitStepItem[]>(
    initialData.map((data) => ({
      ...data,
      selectedFields: data.distributionChannelContent.integrationsResult.cyclr?.action.fields.reduce(
        (prev, field) => ({
          ...prev,
          [field.fieldId]: true,
        }),
        {}
      ),
    }))
  );

  const handleSetField = (connector: DistributionChannelConnector, fieldId: number, checked: boolean) => {
    setData((prev) => {
      const newData = prev.map((oldItem) => {
        if (oldItem.connector.name === connector.name) {
          return {
            ...oldItem,
            selectedFields: { ...oldItem.selectedFields, [fieldId]: checked },
            isUpdateSuccess: false,
          };
        } else {
          return oldItem;
        }
      });

      return newData;
    });
  };

  const handleSetIsUpdateSuccessful = (connector: DistributionChannelConnector) => {
    setData((prev) => {
      const newData = prev.map((oldItem) => {
        if (oldItem.connector.name === connector.name) {
          return {
            ...oldItem,
            isUpdateSuccess: true,
          };
        } else {
          return oldItem;
        }
      });

      return newData;
    });
  };

  const [updateDistributionChannelFields, { isLoading: isUpdateFieldsLoading }] = useUpdateDistributionChannelFieldsMutation();

  const handleClickLaunchCampaign = () => {
    data
      .filter((item) => !item.isUpdateSuccess)
      .forEach(async (item) => {
        const fieldsToIgnore = item.distributionChannelContent.integrationsResult.cyclr.action.fields
          .filter((field) => !item.selectedFields[field.fieldId])
          .map((field) => ({
            stepId: field.stepId,
            fieldId: field.fieldId,
            mappingType: 'Ignore',
            value: field.value,
          }));
        if (fieldsToIgnore.length === 0) {
          handleSetIsUpdateSuccessful(item.connector);
          return;
        }
        const result = await updateDistributionChannelFields({
          workspaceId: workspace.id,
          audienceId: item.distributionChannelContent.distributionChannel.audienceId,
          distributionChannelId: item.distributionChannelContent.distributionChannel.id,
          fields: fieldsToIgnore,
        });

        if ('data' in result) {
          handleSetIsUpdateSuccessful(item.connector);
        }
      });
  };

  const [patchDistributionChannel] = usePatchDistributionChannelMutation();
  const [createCampaign, { isSuccess: isCampaignCreated }] = useCreateCampaignMutation();
  const [isCampaignLaunching, setIsCampaignLaunching] = useState(false);

  const syncEvents = async () => {
    try {
      setIsCampaignLaunching(true);
      await Promise.all(
        data.map(async (data) => {
          await patchDistributionChannel({
            workspaceId: workspace.id,
            audienceId: data.distributionChannelContent.distributionChannel.audienceId,
            distributionChannelId: data.distributionChannelContent.distributionChannel.id,
            parameters: {
              activate: true,
              allowAddUsers: true,
              allowRemoveUsers: true,
              allowUpdateUsers: true,
            },
          });
        })
      );
    } catch (error) {
      setIsCampaignLaunching(false);
    }
  };

  useEffect(() => {
    try {
      if (data.every((item) => item.isUpdateSuccess)) {
        syncEvents().then(() => {
          setIsCampaignLaunching(true);
          const createdDataSource = campaignData.createdDataSources.find((ds) => campaignData.selectedAudience.datasourceIds.includes(ds.id));

          createCampaign({
            workspaceId: workspace.id,
            name: campaignData.name,
            description: campaignData.description,
            audienceId: campaignData.selectedAudience.id,
            destinationTypes: data.map((c) => c.connector.name),
            datasourceTypes: createdDataSource ? [createdDataSource.type.id] : [],
          });
        });
      }
    } finally {
      setIsCampaignLaunching(false);
    }
  }, [data]);

  useEffect(() => {
    if (isCampaignCreated) {
      closeModal();
    }
  }, [isCampaignCreated]);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex flex-col p-6 gap-7 grow'>
        <div className='text-left'>
          <h3 className='text-black text-base font-bold'>{t('specifyTheTraitsYouWantToSend')}</h3>
          <p className='text-base'>{t('youCanSelectAllTheAvailableTraits')}</p>
        </div>
        <div className='flex flex-col gap-8'>
          {data.map((data) => (
            <DestinationItemData key={data.connector.name} data={data} setField={handleSetField} />
          ))}
        </div>
      </div>
      <div className='flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg'>
        <Button onClick={handleClickLaunchCampaign} loading={isUpdateFieldsLoading || isCampaignLaunching}>
          {t('launchTheCampaign')}
        </Button>
      </div>
    </div>
  );
};
