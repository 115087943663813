import { SearchIcon } from '@heroicons/react/outline';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataSourceCard, DataSourceTypePanel } from '..';
import { SpinnerIcon } from '../../../../components/icons';
import { Input } from '../../../../components/inputs';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useDelayedBoolean } from '../../../../hooks/useDelayedBoolean';
import { useSlideOverContext } from '../../../../hooks/useSlideOverContext';
import { DataSourceType } from '../../../../models/dataSource';
import { useGetDataSourceTypesQuery } from '../../../../services/dataSources';

interface IDataSourceCatalogProps {
  onConfirmClicked: (dataSourceType: DataSourceType) => void;
}

const DataSourceCatalog: FunctionComponent<IDataSourceCatalogProps> = ({ onConfirmClicked }) => {
  const { t } = useTranslation();
  const { openSlideOver } = useSlideOverContext();

  const { isFetching, data: sources } = useGetDataSourceTypesQuery();
  // get requested data sources
  // const { data: requestedDataSources } = useGetRequestedDataSourceTypesQuery();

  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, 500);

  const isFetchingDelayed = useDelayedBoolean(isFetching, 400);

  const handleClick = (dataSourceType: DataSourceType) => {
    openSlideOver({
      renderContent: DataSourceTypePanel,
      renderContentProps: { dataSourceType, onAddDataSourceClicked: onConfirmClicked },
    });
  };

  if (isFetchingDelayed) {
    return (
      <span className='text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0'>
        <SpinnerIcon className='-ml-1 mr-3 h-5 w-5' loading />
      </span>
    );
  }

  return (
    <div>
      <div className='flex w-full mb-8'>
        <div className='flex-1 m-auto'>
          <h1 className='text-2xl font-semibold text-gray-900 leading-7'>{t('data_sources:tabs.catalog.source_catalog')}</h1>
        </div>
        <div className='flex-1 flex justify-end'>
          <div className='w-72'>
            <Input
              icon={SearchIcon}
              placeholder={t('data_sources:tabs.catalog.search_sources')}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div>
        <h1 className='text-lg font-semibold text-gray-700 leading-7'>{t('data_sources:tabs.catalog.available_sources')}</h1>
        <div className='grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-8 pt-4 mb-12'>
          {sources
            ?.filter((source) => (debouncedSearchText ? source.name.toLowerCase().indexOf(debouncedSearchText.toLowerCase()) > -1 : true))
            .map((source) => <DataSourceCard key={source.name} dataSourceType={source} onClick={() => handleClick(source)} />)}
        </div>
      </div>
      {/* REQUESTED DATA SOURCES */}
      {/* <div className='mt-16'>
        <h1 className='text-lg font-semibold text-gray-700 leading-7'>{t('data_sources:tabs.catalog.requested_sources')}</h1>
        <h1 className='text-sm font-normal text-gray-600 whitespace-pre-line mt-2'>{t('data_sources:tabs.catalog.requested_sources_description')}</h1>
        <div className='grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-8 pt-4 mb-12 mt-4'>
          {requestedDataSources?.map((source) => (
            <DataSourceCard key={source.name} dataSourceType={source} />
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default DataSourceCatalog;
