import { FunctionComponent } from 'react';
import { BaseAudience } from '../../../../models/audiences';
import { BaseAudienceRow } from './BaseAudienceRow';
import { AudienceCreatedAtColumn, AudienceIconColumn, AudienceIdColumn, AudienceNameColumn, AudienceSyncColumn, AudienceTotalUsersColumn } from './columns';

interface IAudienceRowProp {
  id: string;
  audience: BaseAudience;
  showSync: boolean;
}

const AudienceRow: FunctionComponent<IAudienceRowProp> = ({ id, audience, showSync }) => (
  <BaseAudienceRow id={id} audience={audience}>
    <AudienceIconColumn />
    <AudienceIdColumn audience={audience} />
    <AudienceNameColumn audience={audience} />
    {showSync && <AudienceSyncColumn audience={audience} />}
    <AudienceCreatedAtColumn audience={audience} />
    <AudienceTotalUsersColumn audience={audience} />
  </BaseAudienceRow>
);

export default AudienceRow;
