import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalHeader } from '../../../../../../../../../../../components/modals';
import { EStepStatus, Step } from '../../../../../../../../../../../components/steppers';
import { BaseAudience } from '../../../../../../../../../../../models/audiences';
import { CampaignsValuesApi } from '../../../../../../context';
import { CampaignSetupDataStep } from './CampaignSetupDataStep';
import { CampaignTraitsStep } from './CampaignTraitsStep';
import { CampaignContent } from './types';

type CompleteSetupModalProps = {
  closeModal: () => void;
  forceAudience?: BaseAudience;
  campaignData: CampaignsValuesApi;
  beforeClose?: VoidFunction;
};

export const CompleteSetupModal: FC<CompleteSetupModalProps> = ({ closeModal, campaignData: initialData, beforeClose }) => {
  const { t } = useTranslation('campaign');
  const [content, setContent] = useState<CampaignContent[]>(
    initialData.selectedConnectors.map((connector) => ({
      connector,
    }))
  );

  const allSteps: Step[] = [
    {
      id: 1,
      label: '',
      status: EStepStatus.CURRENT,
    },
    {
      id: 2,
      label: '',
      status: EStepStatus.UPCOMING,
    },
  ];

  const [steps, setSteps] = useState<Step[]>(allSteps);

  const toDataStep = (content: CampaignContent[]) => {
    steps[0]!.status = EStepStatus.COMPLETE;
    steps[1]!.status = EStepStatus.CURRENT;
    setContent(content);
    setSteps(steps);
  };

  const handleCloseModal = useCallback(() => {
    beforeClose();
    closeModal();
  }, [beforeClose, closeModal]);

  const stepTemplate = () => {
    const currentStep = steps.find((step) => step.status === EStepStatus.CURRENT);
    switch (currentStep?.id) {
      case 1:
        return <CampaignSetupDataStep nextStep={toDataStep} initialData={content} campaignData={initialData} />;
      case 2:
        return content && <CampaignTraitsStep initialData={content} campaignData={initialData} closeModal={handleCloseModal} />;
      default:
        return null;
    }
  };

  return (
    <div className='h-full divide-y divide-gray-200 flex flex-col' data-testid='audienceEdit'>
      <div className='min-h-0 flex-1 flex flex-col pt-6 bg-gray-50 rounded-lg'>
        <ModalHeader closeModal={closeModal} title={t('completeYourCampaignSetup')} />
        <div className='mt-6 relative flex-1 bg-white-100 overflow-y-auto flex flex-col'>{stepTemplate()}</div>
      </div>
    </div>
  );
};
