import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loadable } from '../../../../../../../../../components/loaders';
import { useGetEstimateQuery } from '../../../../../../../../../services/endpoints/audiences';
import { useWorkspace } from '../../../../../../../../workspaces/hooks';
import { useCampaignsValues } from '../../../../context';

export const TotalUsersStep: FC = () => {
  const { t } = useTranslation();
  const workspace = useWorkspace();
  const { selectedAudience } = useCampaignsValues();
  const totalUsersCount = 15846;

  const { data: estimateData, isLoading: isEstimateLoading } = useGetEstimateQuery({
    audienceId: selectedAudience.id,
    workspaceId: workspace.id,
  });

  return (
    <div className='flex flex-col *:text-[17px] *:leading-[18px] items-center'>
      <Loadable isLoading={isEstimateLoading}>
        <p className='font-semibold'>{estimateData.size}</p>
        <p>{t('campaign:user', { count: totalUsersCount })}</p>
      </Loadable>
    </div>
  );
};
