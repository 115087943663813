import { Audience, AudienceEstimate, AudienceSyncSchedule, AudienceSyncScheduleType, BaseAudience, BulkAudienceEstimate } from '../../models/audiences';
import { audiencerateApi } from '../audiencerate';

export const audiencesApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    getAllAudiences: build.query<
      {
        audiences: BaseAudience[];
        extendedAudiences: BaseAudience[];
      },
      GetAudiencesArg
    >({
      query: ({ workspaceId, limit, offset }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/all`,
        params: { limit, offset },
      }),
      transformResponse: (response: {
        audiences: { items: BaseAudience[]; limit: number; offset: number };
        extendedAudiences: { items: BaseAudience[]; limit: number; offset: number };
      }) => ({
        audiences: response.audiences.items.map((a) => ({ ...a, extended: false })),
        extendedAudiences: response.extendedAudiences.items.map((a) => ({ ...a, extended: true })),
      }),
      providesTags: ['AllAudiences'],
    }),
    getAudiences: build.query<{ items: Audience[]; limit: number; offset: number }, GetAudiencesArg>({
      query: ({ workspaceId, limit, offset, searchText: q }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences`,
        params: { limit, offset, q },
      }),
      providesTags: ['Audiences'],
    }),
    getAudience: build.query<Audience, GetAudienceArg>({
      query: ({ workspaceId, audienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}`,
      }),
      providesTags: ['Audience'],
    }),
    saveAudience: build.mutation<Audience, SaveAudienceArg>({
      query: ({ workspaceId, audience }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences`,
        method: 'POST',
        body: audience,
      }),
      invalidatesTags: ['Audience', 'Audiences', 'AllAudiences'],
    }),
    updateAudience: build.mutation<Audience, UpdateAudienceArg>({
      query: ({ workspaceId, audienceId, audience }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}`,
        method: 'PATCH',
        body: audience,
      }),
      invalidatesTags: ['Audience', 'Audiences', 'AllAudiences'],
    }),
    deleteAudience: build.mutation<void, GetAudienceArg>({
      query: ({ workspaceId, audienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Audiences', 'AllAudiences'],
    }),
    getEstimate: build.query<AudienceEstimate, GetAudienceEstimateArg>({
      query: ({ workspaceId, audienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/estimate`,
      }),
    }),
    postEstimate: build.mutation<AudienceEstimate, PostAudienceEstimateArg>({
      query: ({ workspaceId, audienceId, audience }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/estimate`,
        method: 'POST',
        body: audience,
      }),
    }),
    getEstimates: build.query<BulkAudienceEstimate[], GetAudiencesEstimatesArg>({
      query: ({ workspaceId, audienceIds }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/estimate`,
        params: { audienceIds },
      }),
    }),
    getAudienceDependenciesQuery: build.query<
      {
        audiences: {
          id: string;
          name: string;
        }[];
        destinations: string[];
      },
      GetAudienceDependenciesArg
    >({
      query: ({ workspaceId, audienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/dependencies`,
      }),
    }),
    getNextSchedules: build.query<GetNextSchedulesResponse, GetNextSchedulesArg>({
      query: ({ workspaceId, times, scheduleType, scheduleConfiguration }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/next-schedules`,
        method: 'POST',
        body: {
          times,
          scheduleType,
          scheduleConfiguration,
        },
      }),
    }),
    getNextSchedule: build.query<GetNextScheduleResponse, GetNextScheduleArg>({
      query: ({ workspaceId, audienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/next-schedule`,
        method: 'GET',
      }),
    }),
    updateAudienceSchedule: build.mutation<Audience, UpdateAudienceScheduleArg>({
      query: ({ workspaceId, audienceId, audience }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}`,
        method: 'PATCH',
        body: audience,
      }),
      invalidatesTags: ['Audiences', 'AllAudiences'],
    }),
    evaluateAudience: build.mutation<Audience, GetAudienceArg>({
      query: ({ workspaceId, audienceId }) => ({
        url: `/v1/workspaces/${workspaceId}/audiences/${audienceId}/evaluate`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

type GetAudiencesArg = {
  workspaceId: string;
  limit?: number;
  offset?: number;
  searchText?: string;
};

type GetAudienceArg = {
  workspaceId: string;
  audienceId: string;
};

type PostAudienceEstimateArg = {
  workspaceId: string;
  audienceId: string;
  audience: Pick<Audience, 'expression'>;
};

type GetAudiencesEstimatesArg = {
  audienceIds: string[];
  workspaceId: string;
};

type GetAudienceEstimateArg = {
  workspaceId: string;
  audienceId: string;
};

type SaveAudienceArg = {
  workspaceId: string;
  audience: Pick<Audience, 'name' | 'datasourceIds' | 'matchingTrait'>;
};

type UpdateAudienceArg = {
  workspaceId: string;
  audienceId: string;
  audience: Pick<Audience, 'name' | 'expression'>;
};

type GetNextSchedulesArg = {
  workspaceId: string;
  times: number;
  scheduleType: AudienceSyncScheduleType;
  scheduleConfiguration: AudienceSyncSchedule;
};

export type GetNextSchedulesResponse = {
  nextSchedules: string[];
};

type GetNextScheduleArg = {
  workspaceId: string;
  audienceId: string;
};

export type GetNextScheduleResponse = {
  nextSchedule: string;
};

type UpdateAudienceScheduleArg = {
  workspaceId: string;
  audienceId: string;
  audience: {
    scheduleType: AudienceSyncScheduleType;
    scheduleConfiguration: AudienceSyncSchedule;
  };
};

type GetAudienceDependenciesArg = {
  workspaceId: string;
  audienceId: string;
};

export const {
  useGetAllAudiencesQuery,
  useGetAudiencesQuery,
  useLazyGetAudiencesQuery,
  useGetAudienceQuery,
  useSaveAudienceMutation,
  useUpdateAudienceMutation,
  useGetEstimateQuery,
  useLazyGetEstimateQuery,
  usePostEstimateMutation,
  useGetEstimatesQuery,
  useDeleteAudienceMutation,
  useGetAudienceDependenciesQueryQuery,
  useLazyGetNextSchedulesQuery,
  useLazyGetNextScheduleQuery,
  useUpdateAudienceScheduleMutation,
  useEvaluateAudienceMutation,
} = audiencesApi;
