import { Form, Formik, FormikProps } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Input } from '../../../../../../components/inputs';
import { DataSourceAmazonS3Config, TDataSource } from '../../../../../../models/dataSource';
import { FileStepContentWrapper } from '../../file/FileStepContentWrapper';

export type AmazonS3FormModel = Partial<TDataSource<DataSourceAmazonS3Config>>;

type NameAndBucketStepProps = {
  formRef: React.RefObject<FormikProps<AmazonS3FormModel>>;
  onSubmit: (form: AmazonS3FormModel) => void;
  dataSource?: Partial<TDataSource<DataSourceAmazonS3Config>>;
};

export const NameAndBucketStep: FC<NameAndBucketStepProps> = ({ formRef, onSubmit, dataSource }) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t('data_source_edit:validations.too_short'))
      .max(50, t('data_source_edit:validations.too_long'))
      .required(t('data_source_edit:validations.required')),
    configuration: Yup.object({
      accessKey: Yup.string().required(t('data_source_edit:validations.required')),
      secretKey: Yup.string().required(t('data_source_edit:validations.required')),
      bucketArn: Yup.string().required(t('data_source_edit:validations.required')),
      path: Yup.string(),
      region: Yup.string().required(t('data_source_edit:validations.required')),
      roleArn: Yup.string(),
    }),
  });

  return (
    <Formik
      innerRef={formRef}
      initialValues={
        dataSource
          ? {
              ...dataSource,
              name: '',
            }
          : {
              name: '',
              configuration: {
                accessKey: '',
                secretKey: '',
                bucketArn: '',
                path: '',
                region: '',
                roleArn: '',
              },
            }
      }
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ values, errors, handleChange, setFieldError }) => {
        return (
          <Form className='max-h-[550px] overflow-y-auto'>
            <FileStepContentWrapper>
              <Input
                name='name'
                id='name'
                type='text'
                label={t('data_source_edit:code.source_details.source_name')}
                value={values?.name}
                placeholder={t('data_source_edit:amazon_s3.source_name_placeholder')}
                onChange={(e) => {
                  setFieldError('name', undefined);
                  handleChange('name')(e.target.value);
                }}
                error={errors?.name}
              />
            </FileStepContentWrapper>
            <label className='block text-sm ps-6 font-medium text-left text-gray-700 capitalize-first py-2 mt-4'>
              {t('data_source_edit:amazon_s3.credentials')}
            </label>
            <FileStepContentWrapper>
              <Input
                name='configuration.accessKey'
                id='configuration.accessKey'
                type='text'
                label={t('data_source_edit:amazon_s3.keyId')}
                labelClassName='capitalize'
                value={values?.configuration.accessKey}
                placeholder={t('data_source_edit:amazon_s3.insertTextHere')}
                onChange={(e) => {
                  setFieldError('configuration.accessKey', undefined);
                  handleChange('configuration.accessKey')(e.target.value);
                }}
                error={errors?.configuration?.accessKey}
              />
              <Input
                name='configuration.secretKey'
                id='configuration.secretKey'
                type='text'
                label={t('data_source_edit:amazon_s3.secretAccessKey')}
                labelClassName='capitalize'
                value={values?.configuration.secretKey}
                placeholder={t('data_source_edit:amazon_s3.insertTextHere')}
                onChange={(e) => {
                  setFieldError('configuration.secretKey', undefined);
                  handleChange('configuration.secretKey')(e.target.value);
                }}
                error={errors?.configuration?.secretKey}
              />
              <Input
                name='configuration.bucketArn'
                id='configuration.bucketArn'
                type='text'
                label={t('data_source_edit:amazon_s3.bucket')}
                labelClassName='capitalize'
                value={values?.configuration.bucketArn}
                placeholder={t('data_source_edit:amazon_s3.insertTextHere')}
                onChange={(e) => {
                  setFieldError('configuration.bucketArn', undefined);
                  handleChange('configuration.bucketArn')(e.target.value);
                }}
                error={errors?.configuration?.bucketArn}
              />
              <Input
                name='configuration.path'
                id='configuration.path'
                type='text'
                label={t('data_source_edit:amazon_s3.path')}
                labelClassName='capitalize'
                value={values?.configuration.path}
                placeholder={t('data_source_edit:amazon_s3.insertTextHere')}
                onChange={(e) => {
                  setFieldError('configuration.path', undefined);
                  handleChange('configuration.path')(e.target.value);
                }}
                error={errors?.configuration?.path}
              />
              <Input
                name='configuration.region'
                id='configuration.region'
                type='text'
                label={t('data_source_edit:amazon_s3.region')}
                labelClassName='capitalize'
                value={values?.configuration.region}
                placeholder={t('data_source_edit:amazon_s3.insertTextHere')}
                onChange={(e) => {
                  setFieldError('configuration.region', undefined);
                  handleChange('configuration.region')(e.target.value);
                }}
                error={errors?.configuration?.region}
              />
              <Input
                name='configuration.roleArn'
                id='configuration.roleArn'
                type='text'
                label={t('data_source_edit:amazon_s3.role')}
                labelClassName='capitalize'
                value={values?.configuration.roleArn}
                placeholder={t('data_source_edit:amazon_s3.insertTextHere')}
                onChange={(e) => {
                  setFieldError('configuration.roleArn', undefined);
                  handleChange('configuration.roleArn')(e.target.value);
                }}
                error={errors?.configuration?.roleArn}
              />
            </FileStepContentWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
