import { PayloadAction } from '@reduxjs/toolkit';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../../../components/tables/search';
import { RootState } from '../../../../../../reducers';
import { CampaignsTable, CampaignsTableItem } from './CampaignsTable';

type CampaignListProps = {
  campaigns: CampaignsTableItem[];
  searchStateSelector: (state: RootState) => string;
  searchStateUpdater: (searchText: string) => PayloadAction<string>;
  pager: ReactNode;
};

export const CampaignList: FC<CampaignListProps> = ({ campaigns, searchStateSelector, searchStateUpdater, pager }) => {
  const { t } = useTranslation('campaign');
  return (
    <div className='pt-[51px]'>
      <SearchInput stateSelector={searchStateSelector} stateUpdater={searchStateUpdater} placeholder={t('searchCampaignByName')} />
      <CampaignsTable campaigns={campaigns} />
      {pager}
    </div>
  );
};
