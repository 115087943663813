import { XIcon } from '@heroicons/react/solid';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../../components/buttons';
import { DataSourceFileConfig, TDataSource } from '../../../../../../models/dataSource';
import { IModalContentProps } from '../../../../../../models/modals';
import { ToastType } from '../../../../../../models/toast';
import { useAppDispatch } from '../../../../../../reducers';
import { useResyncS3DataSourceMutation } from '../../../../../../services/dataSources';
import { showToast } from '../../../../../toasts/toastsSlice';
import { useWorkspace } from '../../../../../workspaces/hooks';

type ResyncS3DataSourceModalProps = {
  dataSource?: TDataSource<DataSourceFileConfig>;
} & IModalContentProps;

export const ResyncS3DataSourceModal: FC<ResyncS3DataSourceModalProps> = ({ dataSource, closeModal }) => {
  const { t } = useTranslation('data_sources');
  const workspace = useWorkspace();

  const [resync, { isLoading, isSuccess }] = useResyncS3DataSourceMutation();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleDeleteDataSourceClicked = () => {
    if (workspace.id && dataSource && dataSource.id)
      resync({
        workspaceId: workspace.id,
        dataSourceId: dataSource.id,
      });
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      history.push('/data-sources');

      dispatch(
        showToast({
          type: ToastType.SUCCESS,
          title: t('resyncS3'),
          message: t('resyncS3Successfully', { dataSourceName: dataSource?.name }),
        })
      );
    }
  }, [isSuccess]);

  return (
    <div className='relative flex flex-col items-center justify-center h-fit p-10 gap-6'>
      <button
        type='button'
        className='bg-white rounded-md text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-700 absolute top-3 right-3'
        onClick={closeModal}
        disabled={isLoading}
      >
        <span className='sr-only capitalize'>{t('closeModal')}</span>
        <XIcon className='h-6 w-6' aria-hidden='true' />
      </button>
      <h1 className='text-lg text-black-100 font-semibold capitalize-first'>{t('confirmManualResync')}</h1>
      <p className='text-base capitalize-first'>{t('youAreGoingToResync')}</p>
      <Button onClick={handleDeleteDataSourceClicked} loading={isLoading} className='capitalize px-6'>
        {t('confirm')}
      </Button>
    </div>
  );
};
