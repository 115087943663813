import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loadable } from '../../../../../../../components/loaders';
import { DistributionChannelConnector } from '../../../../../../../models/distributionChannels';
import { useGetDistributionChannelConnectorsQuery } from '../../../../../../../services/endpoints/distributionChannels';
import { useWorkspace } from '../../../../../../workspaces/hooks';
import { QuickStartStep } from '../../common';
import { DestinationQuickStartItem } from './DestinationQuickStartItem';

export const DestinationSection: FC = () => {
  const { t } = useTranslation();
  const { id: workspaceId } = useWorkspace();
  const { data, isLoading } = useGetDistributionChannelConnectorsQuery({ workspaceId });

  const [selectedDisconnectedConnector, setSelectedDisconnectedConnector] = useState<DistributionChannelConnector | null>(null);

  const handleSelectConnector = (connector: DistributionChannelConnector | null) => {
    setSelectedDisconnectedConnector((prev) => (connector === prev ? null : connector));
  };

  return (
    <QuickStartStep>
      <p className='capitalize-first font-medium text-sm'>{t('campaign:whichPlatformWouldYouLikeToReach')}</p>
      <div className='flex gap-5 min-h-36'>
        <Loadable isLoading={isLoading}>
          {data
            ? data.map((connector) => (
                <DestinationQuickStartItem
                  key={connector.name}
                  connector={connector}
                  selectedDisconnectedConnector={selectedDisconnectedConnector}
                  onSelectedDisconnectedConnector={handleSelectConnector}
                />
              ))
            : null}
        </Loadable>
      </div>
    </QuickStartStep>
  );
};
