import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react/jsx-runtime';
import { twMerge } from 'tailwind-merge';
import { Input } from '../../../../../../../../../../../components/inputs';
import { Loadable } from '../../../../../../../../../../../components/loaders';
import { Audience } from '../../../../../../../../../../../models/audiences';
import {
  CyclrActionParameter,
  CyclrActionTriggerValue,
  DistributionChannelConnector,
  DistributionChannelContent,
  DistributionChannelParameterUpdate,
} from '../../../../../../../../../../../models/distributionChannels';
import {
  useCreateDistributionChannelMutation,
  useDistributionChannelAuthenticatedMutation,
} from '../../../../../../../../../../../services/endpoints/distributionChannels';
import { useCyclrTemplatesQuery } from '../../../../../../../../../../../services/endpoints/integrations';
import { popupCenter } from '../../../../../../../../../../../utils';
import RouteConstants from '../../../../../../../../../../router/RouteConstants';
import { useWorkspace } from '../../../../../../../../../../workspaces/hooks';
import { CampaignDestinationItem } from './types';

type DestinationItemSetupProps = {
  setDistributionChannelContent: (connector: DistributionChannelConnector, content: DistributionChannelContent) => void;
  parameterValueChange: (connector: DistributionChannelConnector, update: DistributionChannelParameterUpdate) => void;
  audience: Audience;
  data: CampaignDestinationItem;
};

export const DestinationItemSetup: FC<DestinationItemSetupProps> = ({ setDistributionChannelContent, parameterValueChange, audience, data }) => {
  const { t } = useTranslation('destinations');
  const workspace = useWorkspace();

  const { connector, distributionChannelContent: defaultDistributionChannel } = data;

  const { data: templates, isLoading: templateIsLoading } = useCyclrTemplatesQuery({
    workspaceId: workspace.id,
  });

  const selectedTemplate = useMemo(() => templates?.find((t) => t.Connectors.find((c) => c.Name === connector.name)), [templates, connector]);

  const [createDistributionChannel, { isLoading: isCreateLoading, data: createData }] = useCreateDistributionChannelMutation();

  const [distributionChannelAuthenticated, { isLoading: isGetLoading, data: getData }] = useDistributionChannelAuthenticatedMutation();

  const [status, setStatus] = useState<string>(defaultDistributionChannel?.integrationsResult?.cyclr?.status || 'CREATE');
  const [distributionChannel, setDistributionChannel] = useState<DistributionChannelContent | undefined>(defaultDistributionChannel);
  const [triggerParam, setTriggerParam] = useState<{ [paramId: string]: CyclrActionTriggerValue | undefined }>({});

  useEffect(() => {
    const tempDistributionChannel = getData || createData || distributionChannel;
    setDistributionChannel(tempDistributionChannel);
    setStatus(tempDistributionChannel?.integrationsResult.cyclr.status || status);
    if (tempDistributionChannel?.integrationsResult.cyclr.status === 'AUTHENTICATION_REQUIRED') {
      const action = tempDistributionChannel.integrationsResult.cyclr.action;
      authenticationScreen(
        `${action.authUrl}?id=${action.accountConnectors[0]}&token=${action.token}&targetOrigin=${location.origin + RouteConstants.cyclrAuthRedirect}`,
        tempDistributionChannel
      );
    } else if (tempDistributionChannel?.integrationsResult.cyclr.status === 'ACTIVABLE') {
      setDistributionChannelContent(connector, tempDistributionChannel);
    } else {
      setDistributionChannelContent(connector, tempDistributionChannel);
    }
  }, [createData, getData]);

  const authenticationScreen = (url: string, distributionChannel: DistributionChannelContent) => {
    (window as any).cyclrAuthenticationCompleted = (success: boolean, w: Window) => {
      w.close.apply(w);
      delete (window as any).cyclrAuthenticationCompleted;
      distributionChannelAuthenticated({
        workspaceId: workspace.id,
        audienceId: audience?.id,
        distributionChannelId: distributionChannel?.distributionChannel.id as any,
      });
    };
    const newWindow = popupCenter({
      url,
      title: 'Authenticate',
      w: 640,
      h: 640,
    });
    (window as any).authWindow = newWindow;
  };

  // TODO: selected template is empty
  const saveDestination = () => {
    createDistributionChannel({
      workspaceId: workspace.id,
      audienceId: audience?.id as string,
      configData: {
        templateId: selectedTemplate?.Id,
        templateName: selectedTemplate?.Name,
      },
    });
  };

  const parameterTemplate = (param: CyclrActionParameter, stepIds?: string[], index = 1) => {
    const translationKey = distributionChannel?.distributionChannel?.name.toLowerCase().replace(/\s+/g, '_');
    const { name, triggerParameterLookupValues } = param;
    const paramName = (triggerParameterLookupValues?.trigger?.Name || name).toLowerCase().replace(/\s+/g, '_');
    return (
      <Fragment key={param.parameterId}>
        <div className={'h-auto text-left font-medium text-gray-800 '}>
          {paramName && <h3 className={'mb-0 font-semibold w-full md:w-2/3 lg:w-1/2'}>{t(`edit.cyclr.${translationKey}.${paramName}` as any)}</h3>}
          {param.triggerParameterLookupValues?.values?.length == 0 && (
            <p className={'mt-5 text-gray-500 italic text-base font-normal w-full md:w-2/3 lg:w-1/2'}>
              {t(`edit.cyclr.${translationKey}.${paramName}_empty` as any)}
            </p>
          )}
          {param.mappingType !== null ? (
            <Fragment>
              {param.triggerParameterLookupValues?.values.map((lookupValue) => (
                <div key={lookupValue.value}>
                  <label className={'flex items-center pt-6 font-normal'}>
                    <input
                      type={'radio'}
                      className={'mr-3'}
                      name={param.parameterId + '_' + index}
                      value={lookupValue.value}
                      onChange={() => {
                        if (lookupValue.nextTrigger) {
                          setTriggerParam({
                            ...triggerParam,
                            [index]: lookupValue,
                          });
                        } else {
                          parameterValueChange(connector, {
                            isAccountConnectorParameter: param.isAccountConnectorParameter,
                            accountConnectorId: param.accountConnectorId,
                            propertyId: param.propertyId,
                            stepIds: stepIds || param.stepIds,
                            parameterId: param.parameterId,
                            mappingType: param.mappingType,
                            value: lookupValue.value,
                            name: lookupValue.name,
                          });
                        }
                      }}
                    />
                    {lookupValue.name}
                  </label>
                </div>
              ))}
            </Fragment>
          ) : (
            <Input
              className={'mt-6 mb-4 w-full md:w-2/3 lg:w-1/2'}
              onChange={(e) => {
                parameterValueChange(connector, {
                  isAccountConnectorParameter: param.isAccountConnectorParameter,
                  accountConnectorId: param.accountConnectorId,
                  propertyId: param.propertyId,
                  stepIds: param.stepIds,
                  parameterId: param.parameterId,
                  mappingType: 'StaticValue',
                  value: e.target.value,
                  name: null,
                });
              }}
            />
          )}
        </div>

        {triggerParam[index]?.nextTrigger &&
          parameterTemplate(
            {
              triggerParameterLookupValues: {
                values: triggerParam[index]?.nextTrigger?.values,
              },
              name: triggerParam[index]?.nextTrigger?.trigger.Name,
              description: '',
              parameterId: param.parameterId,
              mappingType: param.mappingType,
            } as any,
            stepIds || param.stepIds,
            index + 1
          )}
      </Fragment>
    );
  };

  const statusTemplate = () => {
    switch (status) {
      case 'PARAMETERS_REQUIRED':
        return <div className='w-full'>{distributionChannel?.integrationsResult.cyclr.action.parameters.map((param) => parameterTemplate(param))}</div>;
      default:
        return <>Error</>;
    }
  };

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }

    if (status === 'CREATE') {
      saveDestination();
    }
  }, [status, selectedTemplate]);

  const isValid = data.distributionChannelContent?.integrationsResult.cyclr?.action?.parameters?.every(
    (param) => data.selectedParameters[param.parameterId] && data.selectedParameters[param.parameterId].value
  );

  return (
    <div className='flex flex-col'>
      <div
        className={twMerge(
          'flex flex-row border-2 shadow-md border-gray-200 rounded-lg min-h-32 ps-6 pe-3 py-4',
          isValid ? 'border-green-400 ' : 'border-yellow-400'
        )}
      >
        <Loadable isLoading={templateIsLoading || isCreateLoading || isGetLoading}>{statusTemplate()}</Loadable>
        <img src={connector.icon} className='size-9 rounded-full' />
      </div>
    </div>
  );
};
