import { delay } from 'lodash';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useModalContext } from '../../../../../../../hooks/useModalContext';
import { useSlideOverContext } from '../../../../../../../hooks/useSlideOverContext';
import { DataSourceType } from '../../../../../../../models/dataSource';
import { EStatus } from '../../../../../../../models/fileUpload';
import { SLIDEOVER_TRANSITION_DURATION } from '../../../../../../../providers';
import { RootState, useAppDispatch } from '../../../../../../../reducers';
import { clearIntegrationData } from '../../../../../../cyclr/dataSourceIntegrationSlice';
import { DataSourceEdit } from '../../../../../../dataSourceEdit';
import { clearStashedDataSource, clearStashedFileDataSource } from '../../../../../../dataSources/dataSourcesSlice';
import { resetUploadStatus } from '../../../../../../fileUploading/fileUploadingSlice';
import { clearStashedRules } from '../../../../../../rules/rulesSlice';
import { QuickStartItem } from '../../common';
import { useCampaignsActions } from '../../context';

type DataSourceQuickStartItemProps = {
  dataSource: DataSourceType;
};

export const DataSourceQuickStartItem: FC<DataSourceQuickStartItemProps> = ({ dataSource }) => {
  const fileUploadStatus = useSelector((state: RootState) => state.fileUploading.status);
  const dispatch = useAppDispatch();
  const { closeSlideOver } = useSlideOverContext();
  const { openModal } = useModalContext();

  const { addDataSource } = useCampaignsActions();

  const onAddDataSourceClicked = (dataSourceType: DataSourceType) => {
    if (fileUploadStatus?.uploadingStatus !== EStatus.UPLOADING) {
      dispatch(resetUploadStatus());
      dispatch(clearStashedFileDataSource());
    }

    dispatch(clearStashedDataSource());
    dispatch(clearStashedRules());
    dispatch(clearIntegrationData());

    closeSlideOver();
    delay(() => {
      openModal({
        renderContent: DataSourceEdit,
        renderContentProps: {
          dataSourceType,
          onSuccess: (dataSource) => {
            addDataSource(dataSource);
          },
        },
        fullHeight: true,
        fullWidth: true,
      });
    }, SLIDEOVER_TRANSITION_DURATION);
  };

  return <QuickStartItem onClick={() => onAddDataSourceClicked(dataSource)} title={dataSource.name} icon={dataSource.logoUrl} />;
};
