import _ from 'lodash';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SpinnerIcon } from '../../../../../../components/icons';
import Paginated from '../../../../../../components/tables/pagination/Paginated';
import { useDelayedBoolean } from '../../../../../../hooks/useDelayedBoolean';
import { RootState } from '../../../../../../reducers';
import { useGetEstimatesQuery } from '../../../../../../services/endpoints/audiences';
import { useLazyGetCampaignsQuery } from '../../../../../../services/endpoints/campaigns';
import { CampaignResponse } from '../../../../../../services/endpoints/campaigns/types';
import { MainContentWrapper } from '../../../../../layout/main';
import { useWorkspace } from '../../../../../workspaces/hooks';
import { setRegularPagination, setRegularSearchText } from '../../../../campaignsSlice';
import { CampaignList } from './CampaignList';

const MINIMUM_LOADING_DURATION = 400;

export const CampaignsListTab: FC = () => {
  const workspace = useWorkspace();

  const searchText = useSelector((state: RootState) => state.campaigns.ui.regular.searchText);

  const [getCampaigns, { data, isFetching: isCampaignsFetching, isLoading: isCampaignsLoading, isUninitialized: isCampaignsUninitialized }] =
    useLazyGetCampaignsQuery();
  const [campaings, setCampaigns] = useState<CampaignResponse[]>([]);

  const {
    data: estimates,
    isFetching: isEstimatesFetching,
    isLoading: isEstimatesLoading,
  } = useGetEstimatesQuery({
    workspaceId: workspace.id,
    audienceIds: campaings?.map((c) => c.audienceId),
  });

  const tableData = useMemo(() => {
    const test = _.mergeWith(_.keyBy(campaings, 'audienceId'), _.keyBy(estimates, 'audienceId'), (obj, src) => ({
      ...obj,
      ...src,
    }));

    const result = _.values(test);

    return result;
  }, [campaings, estimates]);

  const isLoadingDelayed = useDelayedBoolean(isCampaignsLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isCampaignsLoading || isEstimatesLoading || isLoadingDelayed || isCampaignsUninitialized;
  const isFetching = isCampaignsFetching || isEstimatesFetching;

  const queryCallback = useCallback(
    (pageNo: number, pageSize: number) =>
      getCampaigns({
        workspaceId: workspace.id,
        offset: pageNo * pageSize,
        limit: pageSize,
        searchText: searchText && searchText.length > 2 ? searchText : undefined,
      }),
    [searchText]
  );

  const renderContent = (pager: React.ReactNode) => {
    return shouldShowLoading ? (
      <Fragment>
        <MainContentWrapper className='flex items-center justify-center'>
          <span className='text-blue-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0'>
            <SpinnerIcon className='-ml-1 mr-3 h-5 w-5' loading />
          </span>
        </MainContentWrapper>
      </Fragment>
    ) : (
      <CampaignList
        campaigns={tableData}
        searchStateSelector={(state: RootState) => state.campaigns.ui.regular.searchText}
        searchStateUpdater={setRegularSearchText}
        pager={pager}
      />
    );
  };

  return (
    <Paginated
      stateSelector={(state) => state.campaigns.ui.regular.pagination}
      stateUpdater={setRegularPagination}
      query={queryCallback}
      queryResponse={data ? data.items : undefined}
      setItems={setCampaigns}
      querying={isFetching}
    >
      {renderContent}
    </Paginated>
  );
};
