import { useContext } from 'react';
import { CampaignsValuesContext } from './context';

export const useCampaignsValues = () => {
  const context = useContext(CampaignsValuesContext);

  if (!context) {
    throw new Error('useCampaignsValues must be used within a CampaignsProvider');
  }

  return context;
};
