import { format as formatFn } from 'date-fns';
import { FunctionComponent } from 'react';

type TimeAgoProps = {
  time: string | number | Date | null | undefined;
  thresholdInMinutes?: number;
  format?: string;
};

const TimeAgo: FunctionComponent<TimeAgoProps> = ({ time, format = 'yyyy-MM-dd HH:mm' }) => {
  if (time) {
    if (typeof time === 'string' || typeof time === 'number') {
      try {
        time = new Date(time);
      } catch (e) {
        return null;
      }
    }
    return <>{formatFn(time, format)}</>;
  }
  return <></>;
};

export default TimeAgo;
