import { FormikProps } from 'formik';
import { first, last } from 'lodash';
import { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DataSourceHeader, FileDataSourceContent, FileDataSourceProgressBar } from '../..';
import { Button } from '../../../../../components/buttons';
import { EStepStatus, Step, Stepper } from '../../../../../components/steppers';
import { DataSource, DataSourceType, Header } from '../../../../../models/dataSource';
import { EStatus } from '../../../../../models/fileUpload';
import { RootState, useAppDispatch } from '../../../../../reducers';
import { useSaveDataSourceMutation } from '../../../../../services/dataSources';
import { useGetTraitsQuery } from '../../../../../services/endpoints/traits';
import { setDataSourceTabs, stashFileDataSource } from '../../../../dataSources/dataSourcesSlice';
import { useWorkspace } from '../../../../workspaces/hooks';
import { EFileSteps } from '../../../constants';
import { stashSteps } from '../../../dataSourceEditSlice';
import { useDataSourceSteps } from '../../../hooks/useDataSourceSteps';
import { TColumnItem, TRAITS } from '../../../types';
import { FileDataSourceUploadFormModel } from './FileDataSourceUploadForm';

interface IFileDataSourceContainerProps {
  dataSourceType: DataSourceType;
  closeModal: () => void;
  onSuccess?: (createdDataSource: Partial<DataSource>) => void;
}

const FileDataSourceContainer: FunctionComponent<IFileDataSourceContainerProps> = ({ dataSourceType, closeModal, onSuccess }) => {
  const { t } = useTranslation('data_source_edit');

  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const formRef = useRef<FormikProps<FileDataSourceUploadFormModel>>(null);
  const columnMapFormRef = useRef<FormikProps<TColumnItem[]>>(null);

  const tabs = useSelector((state: RootState) => state.dataSources.ui.tabs);
  const fileDataSource = useSelector((state: RootState) => state.dataSources.edit?.fileDataSource);
  const fileStatus = useSelector((state: RootState) => state.fileUploading.status);
  const fileInfo = useSelector((state: RootState) => state.fileUploading.fileInfo);
  const fileData = useSelector((state: RootState) => state.fileUploading.fileData);

  const [steps, handleNextStep, handlePrevStep] = useDataSourceSteps();

  const [saveDataSource, { isLoading: isLoading }] = useSaveDataSourceMutation();
  const { data: groupedTraits } = useGetTraitsQuery({ workspaceId: workspace.id });

  const currentStep = steps.find((s) => s.status === EStepStatus.CURRENT);
  const isLastStepCurrent = last(steps)?.status === EStepStatus.CURRENT;
  const isFirstStepCompleted = first(steps)?.status === EStepStatus.COMPLETE;

  const fileSteps: Step[] = [
    {
      id: EFileSteps.NAME_AND_FILE,
      label: t('file.steps.name_and_file'),
      status: EStepStatus.CURRENT,
    },
    {
      id: EFileSteps.HEADER,
      label: t('file.steps.header'),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EFileSteps.DATA_TYPE,
      label: t('file.steps.data_type'),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EFileSteps.MAP_COLUMNS,
      label: t('file.steps.map_columns'),
      status: EStepStatus.UPCOMING,
    },
    {
      id: EFileSteps.CONFIRMATION,
      label: t('file.steps.confirmation'),
      status: EStepStatus.UPCOMING,
    },
  ];

  useEffect(() => {
    dispatch(stashSteps(fileSteps));
  }, []);

  const handlePrevButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;

    if (currentStepId === EFileSteps.MAP_COLUMNS) {
      await columnMapFormRef.current?.submitForm();

      if (columnMapFormRef.current?.isValid && columnMapFormRef.current?.values) {
        const columnMap: TColumnItem[] = columnMapFormRef.current?.values;
        const headers: Header[] = getHeadersFromColumnItem(columnMap);

        if (fileDataSource && fileDataSource.name && fileDataSource.configuration) {
          dispatch(stashFileDataSource({ configuration: { ...fileDataSource.configuration, headers: headers } }));
          handlePrevStep();
        }
      }
      return;
    }
    handlePrevStep();
  };

  const handleNextButtonClicked = async () => {
    const currentStepId = currentStep?.id || 0;
    if (currentStepId === EFileSteps.NAME_AND_FILE) {
      await formRef.current?.submitForm();

      if (formRef.current?.isValid && formRef.current?.values) {
        dispatch(stashFileDataSource({ ...formRef.current.values }));
        handleNextStep();
      }

      return;
    }

    if (currentStepId === EFileSteps.HEADER) {
      if (fileDataSource && fileDataSource.configuration && fileData?.isDefaultHeader !== undefined) {
        dispatch(stashFileDataSource({ configuration: { ...fileDataSource.configuration, isDefaultHeader: fileData.isDefaultHeader } }));
        handleNextStep();
      }
    }

    if (currentStepId === EFileSteps.MAP_COLUMNS) {
      await columnMapFormRef.current?.submitForm();

      if (columnMapFormRef.current?.isValid && columnMapFormRef.current?.values) {
        const columnMap: TColumnItem[] = columnMapFormRef.current?.values;

        const headers: Header[] = getHeadersFromColumnItem(columnMap);

        if (fileDataSource && fileDataSource.name && fileDataSource.configuration) {
          dispatch(stashFileDataSource({ configuration: { ...fileDataSource.configuration, headers: headers } }));
          handleNextStep();
        }
      }
      return;
    }

    if (currentStepId === EFileSteps.CONFIRMATION) {
      if (fileDataSource && fileDataSource.name && fileDataSource.configuration) {
        const response = await saveDataSource({
          workspaceId: workspace.id,
          dataSource: {
            type: dataSourceType.id,
            name: fileDataSource.name,
            configuration: {
              isDefaultHeader: fileDataSource.configuration?.isDefaultHeader,
              headers: fileDataSource.configuration?.headers,
              type: fileDataSource.configuration?.type,
              url: fileDataSource.configuration?.url || fileStatus?.data?.path,
            },
          },
        });

        if ('data' in response) {
          onSuccess?.(response.data.dataSource);
        }

        closeModal();
        dispatch(setDataSourceTabs(tabs.map((t) => ({ ...t, current: t.id === 'datasource.table' }))));
      }
      return;
    }

    handleNextStep();
  };

  const getHeadersFromColumnItem = (columnMap: TColumnItem[]): Header[] => {
    return columnMap.map((column) => {
      if (column.trait.id !== TRAITS.IGNORE_COLUMN) {
        return {
          name: column.header,
          traitId: column.traitValue?.id || '',
          displayName: column.traitValue?.displayValue || '',
          custom: column.trait.id === TRAITS.CUSTOM_TRAIT,
          customDataType: column.customDataType,
          skip: false,
        };
      }
      return {
        name: column.header,
        skip: column.trait.id === TRAITS.IGNORE_COLUMN,
      };
    });
  };

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <div className='h-full divide-y divide-gray-200 flex flex-col'>
      <div className='flex-1 flex flex-col pt-6 bg-gray-50 rounded-lg'>
        <DataSourceHeader closeModal={handleCloseModal} title={t('title', { name: dataSourceType.name })} loading={isLoading} />
        <div className='mt-6 py-6 relative flex-1 px-4 sm:px-6 bg-white-100'>
          <Stepper steps={steps} />
          <FileDataSourceContent
            dataSourceType={dataSourceType}
            groupedTraits={groupedTraits}
            step={currentStep}
            formRef={formRef}
            columnMapFormRef={columnMapFormRef}
            goNext={handleNextButtonClicked}
            loading={isLoading}
            closeModal={closeModal}
          />
        </div>
      </div>
      <div className='flex'>
        <div className='flex flex-1 flex-shrink-0 p-4 m-auto h-20 justify-start bg-gray-50 rounded-bl-lg w-96'>
          {!isLastStepCurrent && (
            <FileDataSourceProgressBar
              fileUpload={
                fileStatus?.uploadingStatus !== undefined
                  ? {
                      status: fileStatus.uploadingStatus,
                      percentage: fileStatus.progress || { total: 0, loaded: 0, percentageCompleted: 0 },
                    }
                  : {
                      status: EStatus.NOT_SELECTED,
                      percentage: { total: 0, loaded: 0, percentageCompleted: 0 },
                    }
              }
              filename={fileInfo?.filename || ''}
            />
          )}
        </div>
        <div className='flex flex-1 flex-shrink-0 px-4 py-4 items-center h-full m-auto justify-end bg-gray-50 rounded-br-lg'>
          {isFirstStepCompleted && (
            <Button
              variant='light'
              type='button'
              className='bg-white h-10 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'
              onClick={handlePrevButtonClicked}
              disabled={isLoading}
            >
              {t('back')}
            </Button>
          )}
          <div className={!isLastStepCurrent && fileStatus?.uploadingStatus === EStatus.UPLOADING ? 'has-tooltip' : ''}>
            <span className='tooltip w-auto origin-top-right right-2 rounded shadow-xl -mt-12 p-2 bg-white-100 text-gray-900'>
              {t('file.proceed_with_background_upload')}
            </span>
            <Button
              variant={!isLastStepCurrent ? 'primary' : 'confirm'}
              type='submit'
              className='ml-4 inline-flex justify-center py-2 px-4 h-10'
              onClick={handleNextButtonClicked}
              disabled={isLastStepCurrent && fileStatus?.uploadingStatus === EStatus.UPLOADING}
              loading={isLoading}
            >
              {t(!isLastStepCurrent ? 'next' : 'finish')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDataSourceContainer;
