import { FC } from 'react';
import { Checkbox } from '../../../../../../../../../../../components/inputs';
import { DistributionChannelConnector } from '../../../../../../../../../../../models/distributionChannels';
import { CampaignTraitStepItem } from './CampaignTraitsStep';

type DestinationItemDataProps = {
  data: CampaignTraitStepItem;
  setField: (connector: DistributionChannelConnector, fieldId: number, checked: boolean) => void;
};

export const DestinationItemData: FC<DestinationItemDataProps> = ({ data, setField }) => {
  const { connector, selectedFields, distributionChannelContent } = data;
  const changeField = (fieldId: number, checked: boolean) => {
    setField(connector, fieldId, checked);
  };

  return (
    <>
      <div className='border-solid border border-gray-200 rounded-lg h-auto px-6 py-4 text-left font-medium shadow flex w-full'>
        <div className='w-full'>
          {distributionChannelContent.integrationsResult.cyclr.action.fields
            .filter((x) => !x.name.toLowerCase().includes('[script]'))
            .map((field, i, fields) => (
              <Checkbox
                key={field.fieldId}
                label={field.name}
                id={field.fieldId + ''}
                description={field.description}
                disabled={!field.isOptional}
                required={!field.isOptional}
                forceShowingDescription={i !== fields.length - 1}
                checked={selectedFields[field.fieldId]}
                onChange={(e) => changeField(field.fieldId, e.target.checked)}
              />
            ))}
        </div>
        <img src={connector.icon} className='size-9 rounded-full' />
      </div>
    </>
  );
};
