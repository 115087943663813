import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../reducers';
import CSVTable from '../../file/table/CSVTable';

const DEFAULT_HEADER = 'defaultHeader';
const ITEM_HEADER = 'itemHeader';

const AmazonS3DataSourceCSVHeader: FC = () => {
  const csvFileData = useAppSelector((state) => state.fileUploading.fileData);
  const [csvHeader, setCSVHeader] = useState<string>(csvFileData?.isDefaultHeader ? DEFAULT_HEADER : ITEM_HEADER);

  const { t } = useTranslation('data_source_edit');

  return (
    <div className='flex flex-col justify-start'>
      <div className='block justify-start text-left mb-8'>
        <span className='text-gray-700 text-sm'>{t('file.header.is_this_header')}</span>
        <div className='mt-2 ml-2'>
          <div className='flex justify-start my-1'>
            <label className='inline-flex cursor-pointer'>
              <div className='flex m-auto'>
                <input
                  type='radio'
                  className='form-radio cursor-pointer'
                  name='radio'
                  value={DEFAULT_HEADER}
                  checked={csvHeader === DEFAULT_HEADER}
                  onChange={() => setCSVHeader(DEFAULT_HEADER)}
                />
              </div>
              <span className='ml-2 text-gray-700 text-sm'>{t('file.header.yes_this_is_the_header')}</span>
            </label>
          </div>
          <div className='flex justify-start my-1'>
            <label className='inline-flex cursor-pointer'>
              <div className='flex m-auto'>
                <input
                  type='radio'
                  className='form-radio cursor-pointer'
                  name='radio'
                  value={ITEM_HEADER}
                  checked={csvHeader === ITEM_HEADER}
                  onChange={() => setCSVHeader(ITEM_HEADER)}
                />
              </div>
              <span className='ml-2 text-gray-700 text-sm'>{t('file.header.no_its_an_item')}</span>
            </label>
          </div>
        </div>
      </div>
      <div>
        {csvFileData ? (
          <div className='flex'>
            <div className='flex-initial'>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 my-2' fill='#276EF1' viewBox='0 0 24 24' stroke='#276EF1'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M13 7l5 5m0 0l-5 5m5-5H6' />
              </svg>
            </div>
            <div className='flex-auto overflow-x-auto'>
              <CSVTable fileContent={csvFileData.preview} fileHeader={csvFileData.header} isDefaultHeaderCorrect={csvHeader === DEFAULT_HEADER} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AmazonS3DataSourceCSVHeader;
