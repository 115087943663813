import { FC } from 'react';
import { MainContentWrapper } from '../../../layout/main';
import { Topbar } from '../../../layout/topbar';
import { AudiencesSection, CampaignSection, CampaignsProvider, DataSourceSection, DestinationSection, RecapSection } from './parts';

export const CreateCampaign: FC = () => (
  <CampaignsProvider>
    <Topbar />
    <MainContentWrapper childClassName='h-full flex flex-col px-10 divide-y divide-gray-400'>
      <CampaignSection />
      <DestinationSection />
      <DataSourceSection />
      <AudiencesSection />
      <RecapSection />
    </MainContentWrapper>
  </CampaignsProvider>
);
