export type TabType = {
  id: ETABS | string;
  label: string;
  current: boolean;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export type DataSourceTopbarTab = {
  id: 'datasource.table' | 'datasource.catalog';
  current: boolean;
};

export type AudienceTopbarTab = {
  id: 'audience.regular' | 'audience.extended';
  current: boolean;
};

export type CampaignsTopbarTab = {
  id: 'campaign.regular' | 'campaign.extended';
  current: boolean;
};

export type DestinationTopbarTab = {
  id: 'destination.sync' | 'destination.list';
  current: boolean;
};

export enum ETABS {
  OVERVIEW,
  DATA,
  SETTINGS,
}
