import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { SpinnerIcon } from '../../components/icons';
import { useDelayedBoolean } from '../../hooks/useDelayedBoolean';
import { RootState, useAppDispatch } from '../../reducers';
import { useGetCampaignsQuery } from '../../services/endpoints/campaigns';
import { MainContentWrapper } from '../layout/main';
import { useWorkspace } from '../workspaces/hooks';
import { reset } from './campaignsSlice';
import { CampaignTabs, CreateCampaign } from './components';

const MINIMUM_LOADING_DURATION = 400;

export const Campaigns: FC = () => {
  const dispatch = useAppDispatch();
  const workspace = useWorkspace();

  const uiState = useSelector((state: RootState) => state.campaigns.ui);
  if (!uiState.regular) {
    dispatch(reset());
  }

  const {
    data: campaings,
    isLoading,
    isUninitialized,
    isFetching,
  } = useGetCampaignsQuery({
    workspaceId: workspace.id,
    offset: 0,
    limit: 1,
  });

  const isLoadingDelayed = useDelayedBoolean(isLoading, MINIMUM_LOADING_DURATION);
  const shouldShowLoading = isLoading || isLoadingDelayed || isUninitialized;

  return shouldShowLoading ? (
    <>
      <MainContentWrapper className='flex items-center justify-center'>
        <span className='text-blue-500 opacity-75'>
          <SpinnerIcon className='size-5' loading />
        </span>
      </MainContentWrapper>
    </>
  ) : isFetching || (campaings && !isEmpty(campaings)) || Boolean(uiState.regular.searchText) ? (
    <CampaignTabs />
  ) : (
    <CreateCampaign />
  );
};
