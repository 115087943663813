import { CDPUser, UserInfo, UserPasswordPolicy, UserPasswordTicketResponse } from '../../models/user';
import { audiencerateApi } from '../audiencerate';

const usersApi = audiencerateApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfo, void>({
      query: () => ({
        url: `/v1/userinfo`,
      }),
      providesTags: ['userInfo'],
    }),
    getUserPasswordPolicy: builder.query<UserPasswordPolicy, void>({
      query: () => ({
        url: `/v1/users/self/password-policy`,
      }),
    }),
    createPasswordTicket: builder.mutation<UserPasswordTicketResponse, void>({
      query: () => {
        return {
          url: `/v1/users/self/change-password-ticket`,
          method: 'POST',
          body: {
            callback: window.location.origin + '/cb?type=password-change',
            ttlSeconds: 1 * 60 * 60, // 1 hour
          },
        };
      },
    }),
    changeUserPassword: builder.mutation<any, { userId: string }>({
      query: ({ userId }) => ({
        url: `/v1/users/${userId}/change-password`,
        method: 'POST',
      }),
    }),
    changeUserInfo: builder.mutation<CDPUser, ChangeUserInfoBody>({
      query: ({ userId, firstName, lastName }) => ({
        url: `/v1/users/${userId}`,
        method: 'PATCH',
        body: { firstName, lastName },
      }),
      invalidatesTags: ['userInfo', 'WorkspaceUsers', 'CompanyUsers'],
    }),
  }),
  overrideExisting: false,
});

type ChangeUserInfoBody = {
  userId: string;
  firstName: string;
  lastName: string;
};

export const {
  useChangeUserInfoMutation,
  useChangeUserPasswordMutation,
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useGetUserPasswordPolicyQuery,
  useLazyGetUserPasswordPolicyQuery,
  useCreatePasswordTicketMutation,
} = usersApi;
