import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../../../../../components/buttons';
import {
  DistributionChannelConnector,
  DistributionChannelContent,
  DistributionChannelParameterUpdate,
} from '../../../../../../../../../../../models/distributionChannels';
import { useUpdateDistributionChannelParametersMutation } from '../../../../../../../../../../../services/endpoints/distributionChannels';
import { useWorkspace } from '../../../../../../../../../../workspaces/hooks';
import { CampaignsValuesApi } from '../../../../../../context';
import { DestinationItemSetup } from './DestinationItemSetup';
import { CampaignContent, CampaignDestinationItem } from './types';

type CampaignSetupDataStepProps = {
  nextStep: (p: CampaignContent[]) => void;
  initialData: CampaignContent[];
  campaignData: CampaignsValuesApi;
};

export const CampaignSetupDataStep: FC<CampaignSetupDataStepProps> = ({ initialData, nextStep, campaignData }) => {
  const workspace = useWorkspace();
  const { t } = useTranslation('campaign');
  const [intermediateData, setIntermediateData] = useState<CampaignDestinationItem[]>(
    initialData.map((data) => ({
      ...data,
      selectedParameters: {},
    }))
  );

  const handleSetDistributionChannelContent = (connector: DistributionChannelConnector, content: DistributionChannelContent) => {
    setIntermediateData((prev) => {
      const newData = prev.map((oldItem) => {
        if (oldItem.connector.name === connector.name) {
          return {
            ...oldItem,
            distributionChannelContent: content,
          };
        } else {
          return oldItem;
        }
      });

      return newData;
    });
  };

  const handleSetParameter = (connector: DistributionChannelConnector, update: DistributionChannelParameterUpdate) => {
    setIntermediateData((prev) => {
      const newData = prev.map((oldItem) => {
        if (oldItem.connector.name === connector.name) {
          return {
            ...oldItem,
            selectedParameters: { ...oldItem.selectedParameters, [update.parameterId]: update },
            isParametersUpdated: false,
          };
        } else {
          return oldItem;
        }
      });

      return newData;
    });
  };

  const handleSetIsParametersUpdated = (connector: DistributionChannelConnector) => {
    setIntermediateData((prev) => {
      const newData = prev.map((oldItem) => {
        if (oldItem.connector.name === connector.name) {
          return {
            ...oldItem,
            isParametersUpdated: true,
          };
        } else {
          return oldItem;
        }
      });

      return newData;
    });
  };

  const [updateDistributionChannelParameters, { isLoading: isUpdateParametersLoading }] = useUpdateDistributionChannelParametersMutation();

  const handleClickNextStep = async () => {
    const promises = intermediateData
      .filter((item) => !item.isParametersUpdated)
      .map(async (item) => {
        const result = await updateDistributionChannelParameters({
          workspaceId: workspace.id,
          audienceId: campaignData.selectedAudience.id,
          distributionChannelId: item.distributionChannelContent?.distributionChannel.id as string,
          parameters: Object.values(item.selectedParameters),
        });

        if ('data' in result) {
          handleSetDistributionChannelContent(item.connector, result.data as unknown as DistributionChannelContent);
          handleSetIsParametersUpdated(item.connector);
        }
      });

    await Promise.all(promises);
  };

  const isAllParametersUpdated = intermediateData.every((item) => item.isParametersUpdated);

  const isNextButtonDisabled =
    intermediateData.some(
      (x) =>
        !!x.distributionChannelContent?.integrationsResult.cyclr?.action?.parameters?.find(
          (param) => !x.selectedParameters[param.parameterId] || !x.selectedParameters[param.parameterId].value
        )
    ) || isAllParametersUpdated;

  useEffect(() => {
    if (isAllParametersUpdated) {
      nextStep(
        intermediateData.map((item) => {
          return {
            connector: item.connector,
            distributionChannelContent: item.distributionChannelContent,
          } satisfies CampaignContent;
        })
      );
    }
  }, [intermediateData, isAllParametersUpdated]);

  return (
    <>
      <div className='flex flex-col p-6 gap-8 grow'>
        {intermediateData.map((data) => (
          <DestinationItemSetup
            key={data.connector.name}
            audience={campaignData.selectedAudience}
            setDistributionChannelContent={handleSetDistributionChannelContent}
            data={data}
            parameterValueChange={handleSetParameter}
          />
        ))}
      </div>
      <div className='flex-shrink-0 px-4 py-4 flex justify-end bg-gray-50 rounded-b-lg'>
        <Button
          onClick={handleClickNextStep}
          disabled={isNextButtonDisabled || isUpdateParametersLoading}
          loading={isUpdateParametersLoading || isAllParametersUpdated}
        >
          {t('next')}
        </Button>
      </div>
    </>
  );
};
