import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCampaignQuery } from '../../../../../services/endpoints/campaigns';
import { useWorkspace } from '../../../../workspaces/hooks';
import { CampaignDetailsSection } from './common/CampaignDetailsSection';
import { Tile } from './common/Tile';
import { ConnectedDestinationsSection, DatasourcesUsedSection, RulesUsedSection } from './sections';

export const CampaignDetailsDataTab: FC = () => {
  const { id: campaignId } = useParams<{ id: string }>();
  const workspace = useWorkspace();

  const { data: campaign } = useGetCampaignQuery({
    campaignId,
    workspaceId: workspace.id,
  });

  return (
    <div className='grid gap-y-16'>
      <CampaignDetailsSection>
        <Tile className='w-1/3 text-sm font-medium'>{campaign.name}</Tile>
        <Tile className='w-11/12 text-sm'>{campaign.description}</Tile>
      </CampaignDetailsSection>
      <ConnectedDestinationsSection audienceId={campaign.audienceId} />
      <DatasourcesUsedSection audienceId={campaign.audienceId} />
      <RulesUsedSection audienceId={campaign.audienceId} />
    </div>
  );
};
