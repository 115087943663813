import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Audience } from '../../../../../../models/audiences';
import { DataSource } from '../../../../../../models/dataSource';
import { DistributionChannelConnector } from '../../../../../../models/distributionChannels';

export type CampaignsValuesApi = {
  selectedAudience: Audience | null;
  selectedConnectors: DistributionChannelConnector[];
  createdDataSources: Partial<DataSource>[];
  name: string | null;
  description: string | null;
};

type CampaignsActionsApi = {
  selectAudience: (audience: Audience) => void;
  toggleConnector: (connector: DistributionChannelConnector) => void;
  addDataSource: (dataSource: Partial<DataSource>) => void;
  setName: (value: string | null) => void;
  setDescription: (value: string | null) => void;
  reset: VoidFunction;
};

export const CampaignsValuesContext = createContext<CampaignsValuesApi | null>(null);
export const CampaignsActionsContext = createContext<CampaignsActionsApi | null>(null);

export const CampaignsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedAudience, setSelectedAudience] = useState<Audience | null>(null);
  const [selectedConnectors, setSelectedConnectors] = useState<DistributionChannelConnector[]>([]);
  const [createdDataSources, setCreatedDataSourceTypes] = useState<Partial<DataSource>[]>([]);
  const [name, setName] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);

  const selectAudience = useCallback(
    (audience: Audience) => {
      setSelectedAudience((prev) => (prev === audience ? null : audience));
    },
    [setSelectedAudience]
  );

  const toggleConnector = useCallback(
    (connector: DistributionChannelConnector) => {
      setSelectedConnectors((prev) => {
        const index = prev.indexOf(connector);

        if (index === -1) {
          return [...prev, connector];
        }

        return prev.filter((x) => x !== connector);
      });
    },
    [setSelectedConnectors]
  );

  const addDataSource = useCallback(
    (newDataSource: Partial<DataSource>) => {
      setCreatedDataSourceTypes((prevDataSources) => [...prevDataSources, newDataSource]);
    },
    [setCreatedDataSourceTypes]
  );

  const reset = useCallback(() => {
    setSelectedAudience(null);
    setSelectedConnectors([]);
    setCreatedDataSourceTypes([]);
    setName('');
    setDescription('');
  }, [setSelectedAudience, setSelectedConnectors, setName, setDescription]);

  const value = useMemo(
    () =>
      ({
        selectedAudience,
        selectedConnectors,
        createdDataSources,
        name,
        description,
      }) satisfies CampaignsValuesApi,
    [selectedAudience, selectedConnectors, name, description, createdDataSources]
  );

  const actions = useMemo(
    () =>
      ({
        selectAudience,
        toggleConnector,
        addDataSource,
        setName,
        setDescription,
        reset,
      }) satisfies CampaignsActionsApi,
    [selectAudience, toggleConnector, setName, setDescription, reset]
  );

  return (
    <CampaignsValuesContext.Provider value={value}>
      <CampaignsActionsContext.Provider value={actions}>{children}</CampaignsActionsContext.Provider>
    </CampaignsValuesContext.Provider>
  );
};
