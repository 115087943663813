import { useContext } from 'react';
import { CampaignsActionsContext } from './context';

export const useCampaignsActions = () => {
  const context = useContext(CampaignsActionsContext);
  if (!context) {
    throw new Error('useCampaignsActions must be used within a CampaignsProvider');
  }

  return context;
};
