import { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { SpinnerIcon } from '../icons';

type LoadableProps = {
  isLoading: boolean;
  className?: string;
} & PropsWithChildren;

export const Loadable: FC<LoadableProps> = ({ isLoading, className, children }) => {
  if (isLoading) {
    return (
      <div className={twMerge('flex grow items-center justify-center', className)}>
        <SpinnerIcon className='size-6' loading />
      </div>
    );
  }

  return children;
};
