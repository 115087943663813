import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DataSourcesIcon from '../../../../assets/icons/audiences.svg?react';
import { MainContentWrapper } from '../../../layout/main';
import { TopbarTabs } from '../../../layout/topbar';
import { CreateCampaignButton } from './CreateCampaignButton';
import { CampaignsListTab } from './parts';

export const CampaignTabs: FC = () => {
  const { t } = useTranslation('campaign');

  const tabs = [{ id: 'myCampaigns', current: true, label: t('myCampaigns'), icon: () => <DataSourcesIcon className={'inline h-4 w-4 mr-1'} /> }];
  const breadcrumbs = [
    {
      label: t('campaigns'),
    },
  ];

  const renderTab = () => {
    const activeTabId = tabs.find((tab) => tab.current)?.id || '';

    switch (activeTabId) {
      case 'myCampaigns':
        return <CampaignsListTab />;

      default:
        return <></>;
    }
  };

  return (
    <>
      <TopbarTabs tabs={tabs} onTabChange={() => {}} breadcrumbs={breadcrumbs} rightElement={() => <CreateCampaignButton />} />
      <MainContentWrapper childClassName='h-full flex flex-col px-10'>{renderTab()}</MainContentWrapper>
    </>
  );
};
