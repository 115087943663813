import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loadable } from '../../../../../../../components/loaders';
import { EDataSourceCategory } from '../../../../../../../models/dataSource';
import { useGetDataSourceTypesQuery } from '../../../../../../../services/dataSources';
import { QuickStartStep } from '../../common';
import { useCampaignsValues } from '../../context';
import { DataSourceQuickStartItem } from './DataSourceQuickStartItem';

export const DataSourceSection: FC = () => {
  const { t } = useTranslation();
  const { selectedConnectors } = useCampaignsValues();

  const { isFetching, data: sources } = useGetDataSourceTypesQuery();
  const availableSources = sources?.filter(
    (source) => source.category === EDataSourceCategory.CODE || source.category === EDataSourceCategory.FILE || source.category === EDataSourceCategory.CRM
  );

  return (
    <QuickStartStep disabled={selectedConnectors.length === 0}>
      <div className='flex flex-col'>
        <p className='capitalize-first font-medium text-sm'>{t('campaign:wouldYouLikeToAddSomeDatasources')}</p>
        <p className='capitalize-first text-sm'>{t('campaign:availableDatasourcesMessage')}</p>
      </div>
      <div className='flex gap-5 min-h-32'>
        <Loadable isLoading={isFetching}>{availableSources?.map((source) => <DataSourceQuickStartItem key={source.name} dataSource={source} />)}</Loadable>
      </div>
    </QuickStartStep>
  );
};
