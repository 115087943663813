import { ChangeEventHandler, FC } from 'react';
import { Checkbox } from '../../../../../../../../../components/inputs';
import { Audience } from '../../../../../../../../../models/audiences';
import { AudienceCreatedAtColumn, AudienceNameColumn, AudienceSyncColumn, AudienceTotalUsersColumn } from '../../../../../../../../audiences';
import { useCampaignsActions, useCampaignsValues } from '../../../../context';

type QuickStartAudienceRowProps = {
  audience: Audience;
};

export const QuickStartAudienceRow: FC<QuickStartAudienceRowProps> = ({ audience }) => {
  const { selectedAudience } = useCampaignsValues();
  const { selectAudience } = useCampaignsActions();

  const handleToggleRow: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    selectAudience(audience);
  };

  const handleClickRow = () => {
    window.open(`/audiences/${audience.id}`, '_blank');
  };

  return (
    <tr id={audience.id} className='bg-white-100 border-b border-gray-200 cursor-pointer hover:bg-gray-50 last:border-0' onClick={handleClickRow}>
      <Checkbox
        checked={selectedAudience === audience}
        onChange={handleToggleRow}
        onClick={(e) => e.stopPropagation()}
        className='form-radio cursor-pointer ms-4 !border-blue-500'
      />
      <AudienceNameColumn audience={audience} />
      <AudienceSyncColumn audience={audience} />
      <AudienceCreatedAtColumn audience={audience} />
      <AudienceTotalUsersColumn audience={audience} />
    </tr>
  );
};
