import { audiencerateApi } from '../../audiencerate';
import { AmazonS3FilePreviewRequest, AmazonS3FilePreviewResponse } from './types';

export const getS3FilePreviewKey = 'get-S3-file-preview';

export const amazonS3Api = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    getS3FilePreview: build.mutation<AmazonS3FilePreviewResponse, AmazonS3FilePreviewRequest>({
      query: (body) => ({
        url: `/v1/upload/s3/preview`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetS3FilePreviewMutation } = amazonS3Api;
