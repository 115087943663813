import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useModalContext } from '../../../../../../../hooks/useModalContext';
import { RootState, useAppDispatch } from '../../../../../../../reducers';
import { useDeleteAudienceMutation } from '../../../../../../../services/endpoints/audiences';
import { AudienceEdit } from '../../../../../../audienceEdit';
import { clearStashedAudience } from '../../../../../../audienceEdit/audienceEditSlice';
import { AudienceTypeSelector } from '../../../../../../audiences/components/dropdowns';
import { useWorkspace } from '../../../../../../workspaces/hooks';
import { QuickStartStep } from '../../common';
import { useCampaignsValues } from '../../context';
import { QuickStartAudiencesTable } from './parts';

export const AudiencesSection: FC = () => {
  const { t } = useTranslation();
  const workspace = useWorkspace();
  const { selectedConnectors } = useCampaignsValues();

  const [deleteAudience] = useDeleteAudienceMutation();
  const stashedAudience = useSelector((state: RootState) => state.audienceEdit.audience);
  const stashedAudienceRef = useRef(stashedAudience);

  useEffect(() => {
    stashedAudienceRef.current = stashedAudience;
  }, [stashedAudience]);

  const dispatch = useAppDispatch();
  const { openModal } = useModalContext();

  const handleModalClose = () => {
    const createdAudience = stashedAudienceRef.current;
    stashedAudienceRef.current = undefined;

    if (createdAudience && createdAudience.id && Object.keys(createdAudience.expression || {}).length === 0) {
      deleteAudience({
        workspaceId: workspace.id,
        audienceId: createdAudience.id,
      });
    }
  };

  const handleCreateAudience = () => {
    dispatch(clearStashedAudience());

    openModal({
      renderContent: AudienceEdit,
      renderContentProps: {},
      fullHeight: true,
      fullWidth: true,
      onClose: handleModalClose,
    });
  };

  return (
    <QuickStartStep disabled={selectedConnectors.length === 0}>
      <p className='capitalize-first font-medium text-sm'>{t('campaign:whichUsersWouldYouLikeToUse')}</p>
      <div className='grid grid-cols-5 gap-x-12 items-center'>
        <div className='col-span-3'>
          <QuickStartAudiencesTable />
        </div>
        <div className='border-l border-gray-400 h-full flex flex-col justify-center items-center gap-4 col-span-2'>
          <p className='text-sm'>{t('campaign:orCreateSomethingNew')}</p>
          <AudienceTypeSelector onCreateAudience={handleCreateAudience} buttonClassNames='mr-0' />
        </div>
      </div>
    </QuickStartStep>
  );
};
