import { PlusIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/buttons';
import RouteConstants from '../../../router/RouteConstants';

export const CreateCampaignButton: FC = () => {
  const { t } = useTranslation('campaign');
  const history = useHistory();

  return (
    <Button variant='confirm' icon={PlusIcon} className='h-9' onClick={() => history.push(RouteConstants.createCampaign)}>
      {t('createCampaign')}
    </Button>
  );
};
