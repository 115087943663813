import { Form, Formik, FormikProps } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Select } from '../../../../../components/selects';
import { TFileData } from '../../../../../models/fileUpload';
import { GroupedTraits } from '../../../../../models/traits';
import { RootState } from '../../../../../reducers';
import { generateSlug } from '../../../../../utils';
import { useColumnMapInitialValues } from '../../../hooks/useColumnMapInitialValues';
import { IColumnMapSelect, TColumnItem, TRAITS } from '../../../types';
import { TraitInput } from './input';
import DataTypeInput from './input/DataTypeInput';

interface IFileDataSourceColumnMap {
  columnMapFormRef: React.RefObject<FormikProps<TColumnItem[]>>;
  groupedTraits?: GroupedTraits;
}

// 3 rows + header
const MAX_ROWS = 4;

const FileDataSourceColumnMap: FunctionComponent<IFileDataSourceColumnMap> = ({ columnMapFormRef, groupedTraits }) => {
  const { t } = useTranslation('data_source_edit');

  const fileData: TFileData | undefined = useSelector((state: RootState) => state.fileUploading.fileData);

  const columnMapSelect: IColumnMapSelect[] = [
    {
      id: TRAITS.MAP_TO_TRAIT,
      label: t('file.map_column.map_to_trait'),
    },
    {
      id: TRAITS.CUSTOM_TRAIT,
      label: t('file.map_column.custom_trait'),
    },
    {
      id: TRAITS.CONSENT_TRAIT,
      label: t('file.map_column.consent_trait'),
    },
    {
      id: TRAITS.IGNORE_COLUMN,
      label: t('file.map_column.ignore_column'),
    },
  ];

  const fileHeader: string[] = fileData ? [...fileData.header] : [];
  const fileContent: string[][] = fileData
    ? fileData.isDefaultHeader
      ? [...fileData.preview.slice(0, MAX_ROWS).slice(1)]
      : [...fileData.preview.slice(0, MAX_ROWS)]
    : [];

  const validateForm = (values: TColumnItem[]) => {
    const errors: { [key: number]: string }[] = [];
    values.filter((column, index) => {
      switch (column.trait.id) {
        case TRAITS.MAP_TO_TRAIT:
          if (!column.traitValue) {
            errors[index] = t('file.map_column.validation.select_trait');
          }
          break;
        case TRAITS.CUSTOM_TRAIT:
          if (!column.traitValue || !column.traitValue.id || !column.traitValue.displayValue || !column.customDataType) {
            errors[index] = t('file.map_column.validation.value_cannot_be_blank');
          }
          break;
        case TRAITS.CONSENT_TRAIT:
          // in the future we need more validations
          if (!column.traitValue) {
            errors[index] = t('file.map_column.validation.value_cannot_be_blank');
          }
          break;
        default:
          break;
      }
    });
    return errors;
  };

  const initialValues = useColumnMapInitialValues(fileHeader, columnMapSelect, groupedTraits);

  return (
    <Formik
      onSubmit={(values, { validateForm }) => validateForm(values)}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      validate={validateForm}
      innerRef={columnMapFormRef}
    >
      {({ values, setFieldValue, setFieldError, errors }) => (
        <Form>
          {values &&
            fileContent &&
            values.map((columnItem, index) => {
              return (
                <div className='flex border-solid border border-gray-200 rounded-lg mt-3 p-5' key={columnItem.id}>
                  <div className='flex flex-col flex-1 mx-2'>
                    <div className='border border-solid border-blue-300 bg-blue-50 text-blue-700 text-left px-2 py-1'>{columnItem.header}</div>
                    {fileContent.map((fileRows, i) => (
                      <div key={`${fileRows[index]}_${i}`} className='border border-solid border-gray-300 text-gray-700 text-left px-2 py-1'>
                        {fileRows[index]}
                      </div>
                    ))}
                  </div>
                  <div className='flex-1 mx-2'>
                    <Select
                      items={columnMapSelect}
                      className='w-full -mt-1 text-left'
                      value={columnItem.trait}
                      onChange={(selectItemValue) => {
                        setFieldError(String(index), undefined);
                        console.log('columnItem', columnItem, 'selectItemValue', selectItemValue);
                        if (selectItemValue.id == TRAITS.CUSTOM_TRAIT) {
                          setFieldValue(String(index), {
                            ...columnItem,
                            trait: selectItemValue,
                            traitValue: {
                              id: generateSlug(columnItem.header),
                              displayValue: columnItem.header,
                            },
                          });
                        } else {
                          setFieldValue(String(index), { ...columnItem, trait: selectItemValue, traitValue: undefined });
                        }
                      }}
                    />
                  </div>
                  <div className='flex-1 mx-2'>
                    <TraitInput
                      option={columnItem.trait}
                      error={errors[index] as string}
                      value={columnItem.traitValue}
                      onChange={(value) => {
                        setFieldError(String(index), undefined);
                        setFieldValue(String(index), { ...columnItem, traitValue: value });
                      }}
                    />
                  </div>
                  <div className='flex-1 mx-2'>
                    <DataTypeInput
                      option={columnItem.trait}
                      error={errors[index] as string}
                      value={columnItem.customDataType}
                      onChange={(value) => {
                        setFieldError(String(index), undefined);
                        setFieldValue(String(index), { ...columnItem, customDataType: value });
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </Form>
      )}
    </Formik>
  );
};

export default FileDataSourceColumnMap;
