import { FC, Fragment } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../router/components';
import RouteConstants from '../router/RouteConstants';
import { Campaigns } from './Campaigns';
import { CampaignDetails, CreateCampaign } from './components';

export const CampaignsRouter: FC = () => (
  <Fragment>
    <Switch>
      <PrivateRoute path={RouteConstants.createCampaign} component={CreateCampaign} />
      <PrivateRoute path={`${RouteConstants.campaigns}/:id`} component={CampaignDetails} />
      <PrivateRoute path={RouteConstants.campaigns} component={Campaigns} />
    </Switch>
  </Fragment>
);
