import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../../../../components/buttons';
import { useModalContext } from '../../../../../../../../../hooks/useModalContext';
import { useCampaignsActions, useCampaignsValues } from '../../../../context';
import { CompleteSetupModal } from './parts/CompleteSetupModal/CompleteSetupModal';

export const StartNewButton: FC = () => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();
  const initialData = useCampaignsValues();
  const { reset } = useCampaignsActions();

  const openCreateDialog = () => {
    return openModal({
      renderContent: CompleteSetupModal,
      renderContentProps: {
        campaignData: initialData,
        beforeClose: reset,
      },
      fullHeight: true,
      fullWidth: true,
    });
  };

  return (
    <Button variant='confirm' className='ms-3 capitalize w-32' onClick={openCreateDialog}>
      {t('campaign:startNow')}
    </Button>
  );
};
