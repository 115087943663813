import { PlusIcon } from '@heroicons/react/solid';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../components/buttons/Button';

interface IAudienceTypeSelectorProps {
  onCreateAudience: () => void;
  buttonClassNames?: string;
}

const AudienceTypeSelector: FunctionComponent<IAudienceTypeSelectorProps> = ({ onCreateAudience, buttonClassNames }) => {
  const { t } = useTranslation('audiences');

  return (
    <Button variant='confirm' icon={PlusIcon} className={twMerge('h-9 mr-6', buttonClassNames)} onClick={onCreateAudience}>
      {t('buttons.add_audience')}
    </Button>
  );
};

export default AudienceTypeSelector;
