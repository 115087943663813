import { RefreshIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell } from '../../../../../../../components/tables';
import { TimeAgo } from '../../../../../../../components/time-ago';
import RouteConstants from '../../../../../../router/RouteConstants';
import { CampaignsTableItem } from './types';

export const CAMPAIGNS_TABLE_COLUMNS = ['', 'id', 'campaign', 'createdAt', 'totalUsers'] as const;

type CampaignRowProps = {
  campaign: CampaignsTableItem;
};

export const CampaignRow: FC<CampaignRowProps> = ({ campaign }) => {
  const history = useHistory();

  const onAudienceRowClick = () => {
    history.push(`${RouteConstants.campaigns}/${campaign.id}`);
  };

  return (
    <tr
      id={campaign.id}
      className='bg-white-100 border-b border-gray-200 cursor-pointer hover:bg-gray-50 last:border-0 motion-safe:transition-colors motion-safe:duration-300 motion-reduce:transition-none'
      onClick={onAudienceRowClick}
    >
      <TableCell></TableCell>
      <TableCell>{campaign.id}</TableCell>
      <TableCell className='text-blue-500'>{campaign.name}</TableCell>
      <TableCell>
        <TimeAgo time={campaign.createdOn} />
      </TableCell>
      <TableCell>
        <div className='flex gap-2 items-center'>
          <RefreshIcon className='size-4 text-blue-500' />
          {campaign.size}
        </div>
      </TableCell>
    </tr>
  );
};
