import { ArrowRightIcon, RefreshIcon } from '@heroicons/react/solid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import AudiencesIcon from '../../../../../../../assets/icons/audiences.svg?react';
import { ISimpleTableProps, SimpleTable, TableCell } from '../../../../../../../components/tables';
import { TimeAgo } from '../../../../../../../components/time-ago';
import { DestinationsTableItem } from './types';

type IDestinationTableProps = ISimpleTableProps & {
  destinations: DestinationsTableItem[];
};

type RowProps = {
  destination: DestinationsTableItem;
};

const Row: FC<RowProps> = ({ destination }) => (
  <tr id={destination.id} className='bg-white-100 border-b border-gray-200 last:border-0'>
    <TableCell className='flex gap-6 items-center ps-6'>
      <AudiencesIcon />
      {destination.audience.name}
    </TableCell>
    <TableCell>
      <div className='flex gap-9 items-center'>
        <ArrowRightIcon className='w-4 text-gray-500' />
        {destination.name}
      </div>
    </TableCell>
    <TableCell>
      <TimeAgo time={destination.createdOn} />
    </TableCell>
    <TableCell>
      <div className='flex gap-2 items-center'>
        <RefreshIcon className='size-4 text-blue-500' />
        {destination.size}
      </div>
    </TableCell>
  </tr>
);

const DestinationTable: FC<IDestinationTableProps> = ({ destinations, ...rest }) => {
  const { t } = useTranslation('destinations');

  const headings = [
    { label: t('table.audience_name'), className: 'ps-[72px]' },
    { label: t('table.destination_name'), className: 'ps-16' },
    { label: t('table.change') },
    { label: t('table.total_users') },
  ];

  return (
    <SimpleTable {...rest}>
      <thead>
        <tr>
          {headings.map((heading) => (
            <th
              key={heading.label}
              scope='col'
              className={twMerge('p-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider sticky top-0', heading?.className)}
            >
              {heading.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{destinations?.map((destination) => <Row key={destination.id} destination={destination} />)}</tbody>
    </SimpleTable>
  );
};

export default DestinationTable;
